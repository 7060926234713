import React, { useEffect, useState } from "react";
import { Search, Book, Tag, Clock } from "lucide-react";
import { Card, CardContent } from "../ui/card";
import { ButtonUI } from "../ui/button";
import { Input } from "../ui/input";
import { Badge } from "../ui/badge";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";

const ServiceDocs = ({ service, back = false }) => {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const navigate = useNavigate();



  // Mock data for demonstration
  const mockResults = [
    {
      title: "Getting Started with API Integration",
      description:
        "Learn how to integrate our APIs into your application with step-by-step guides and examples.",
      category: "API",
      lastUpdated: "2024-12-28",
      tags: ["api", "integration", "tutorial"],
    },
    {
      title: "Authentication Best Practices",
      description:
        "Comprehensive guide on implementing secure authentication in your applications.",
      category: "Security",
      lastUpdated: "2024-12-27",
      tags: ["security", "auth", "best-practices"],
    },
    {
      title: "Database Schema Design",
      description:
        "Guidelines and best practices for designing efficient database schemas.",
      category: "Database",
      lastUpdated: "2024-12-26",
      tags: ["database", "schema", "design"],
    },
  ];

  const filters = [
    { id: "all", label: "All" },
    { id: "api", label: "API" },
    { id: "security", label: "Security" },
    { id: "database", label: "Database" },
  ];

  const handleReturnToCatalog = () => {
    navigate("/catalog");
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white">
        {
            location.state?.back &&
            (
                <div>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small" // Makes the button small
                  startIcon={<ArrowBackIcon />}
                  onClick={handleReturnToCatalog}
                  sx={{
                    fontSize: "0.75rem", // Adjust font size to be smaller
                    padding: "4px 12px", // Make padding smaller for a compact button
                    marginBottom: "10px",
                  }}
                >
                  Back to Catalog
                </Button>
              </div>
            )
        }
     

      {/* Header */}
      <div className="mb-8">
        <h1 className="text-4xl font-bold mb-2 text-gray-900">
          Developer Documentation
        </h1>
        <p className="text-gray-600 text-lg">
          Search through our documentation, guides, and tutorials
        </p>
      </div>

      {/* Search Bar */}
      <div className="relative mb-6">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-orange-500" />
        </div>
        <Input
          type="text"
          placeholder="Search documentation..."
          className="pl-10 w-full border-2 border-gray-200 focus:border-orange-500 focus:ring-orange-500 h-12 text-lg"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Filters */}
      <div className="flex gap-2 mb-6">
        {filters.map((filter) => (
          <Button
            key={filter.id}
            variant={selectedFilter === filter.id ? "default" : "outline"}
            onClick={() => setSelectedFilter(filter.id)}
            className={`px-6 py-2 text-base font-medium ${
              selectedFilter === filter.id
                ? "bg-orange-500 text-white hover:bg-orange-600"
                : "text-gray-700 border-2 border-gray-200 hover:border-orange-500 hover:text-orange-500"
            }`}
          >
            {filter.label}
          </Button>
        ))}
      </div>

      {/* Results */}
      <div className="space-y-4">
        {mockResults.map((result, index) => (
          <Card
            key={index}
            className="hover:shadow-lg transition-shadow border-2 border-gray-100 hover:border-orange-500"
          >
            <CardContent className="p-6">
              <div className="flex items-start justify-between">
                <div className="w-full">
                  <div className="flex items-center gap-2 mb-3">
                    <Book className="h-6 w-6 text-orange-500" />
                    <h2 className="text-xl font-bold text-gray-900">
                      {result.title}
                    </h2>
                  </div>
                  <p className="text-gray-600 text-lg mb-4">
                    {result.description}
                  </p>
                  <div className="flex items-center gap-6">
                    <div className="flex items-center gap-2">
                      <Tag className="h-4 w-4 text-orange-500" />
                      <span className="text-base text-gray-700">
                        {result.category}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Clock className="h-4 w-4 text-orange-500" />
                      <span className="text-base text-gray-700">
                        Updated: {result.lastUpdated}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 flex gap-2">
                {result.tags.map((tag, tagIndex) => (
                  <Badge
                    key={tagIndex}
                    className="bg-orange-100 text-orange-700 hover:bg-orange-200 px-3 py-1 text-sm"
                  >
                    {tag}
                  </Badge>
                ))}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ServiceDocs;
