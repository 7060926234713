import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

const Logout = () => {
  const { oktaAuth } = useOktaAuth();

  const handleLogout = async () => {
    try {
      await oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin + '/login', // Redirect after logout
      });
      console.log('Logout successful');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Logout;
