import * as React from "react";
import { cn } from "../lib/utils";

// Badge component
const Badge = React.forwardRef(({ className, ...props }, ref) => (
  <span
    ref={ref}
    className={cn(
      "inline-block rounded-md bg-primary px-2 py-1 text-sm font-medium text-white",
      className
    )}
    {...props}
  />
));
Badge.displayName = "Badge";

export { Badge };
