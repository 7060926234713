import { useState } from 'react';
import axios from 'axios';

const useAxios = (baseURL) => {
    const [axiosInstance] = useState(() => {
        const instance = axios.create({
            baseURL: baseURL,
            headers: {
                Accept: "application/json",
                // Authorization: `Bearer ${token}`,            
            }
        });

        // Add interceptors if needed
        instance.interceptors.request.use((config) => {
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        instance.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            switch (error?.response?.status) {
                case 400:

                    break;
                case 401:

                    break;

                default:
                    break;
            }
            return Promise.reject(error);
        });

        return instance;
    });

    return axiosInstance;
};

export default useAxios;

