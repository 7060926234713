import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  loading: false,
  chat: null,
  error: "",
};

// Async action to fetch catalog data
export const sendMessage = createAsyncThunk(
  "retrieveMessage",
  async ({ axiosInstance, message }, { rejectWithValue }) => {
    try {
      console.log("Message sent:", message);
      const response = await axiosInstance.post("/retrieve/stream", {
        query: message,
      });
      return response.data; // This will be the payload in the fulfilled action
    } catch (error) {
      console.error("Error sending message:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Create the slice
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    // Add any reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.loading = true; // Set loading to true when the request starts
        state.error = ""; // Reset the error on new request
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false; // Set loading to false after the request is completed
        state.catalog = action.payload; // Save the fetched catalog data
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.loading = false; // Set loading to false if the request fails
        state.error = action.error.message; // Set the error message from the action
      });
  },
});

export default chatSlice.reducer;

// No need to explicitly export getCatalog again
