import "./ServiceCard.scss";

import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Link,
  Grid,
  Grid2,
  Box,
  Paper,
} from "@mui/material";
import {
  Package,
  GitBranch,
  Clock,
  Users,
  Zap,
  Book,
  LineChart,
} from "lucide-react";
import cert from "../../images/sonic-ready.png";
import { useNavigate } from "react-router-dom";

const ServiceCard = ({ service }) => {
  const navigate = useNavigate();

  const getScoreColor = (score) => {
    if (score >= 80) return "#ECFDF5"; // green
    if (score >= 60) return "#FFE0CC"; // orange
    return "#EF8881"; // red
  };

  const getTextColor = (score) => {
    if (score >= 80) return "#10B981"; // green
    if (score >= 60) return "#E6863C"; // orange
    return "#f44336"; // red
  };

  const handleOpenServicePage = (service) => {
    navigate("/servicePage", { service });
  };

  const handleDocs = (service) => {
    navigate("/serviceDocs", { state: { service: "service", back: true } });
  };

  const handleDeployment = (service) => {
    navigate("/deployment", { state: { service: "deployment", back: true } });
  };
  return (
    <div>
      <Card
        style={{
          backgroundColor: "white",

          margin: "auto",
          transition: "box-shadow 0.2s",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          avatar={
            service.sonicReady ? (
              <Avatar style={{ backgroundColor: "#ff9800" }}>
                <Zap style={{ color: "white" }} />
              </Avatar>
            ) : null
          }
          title={
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item xs={10}>
                <Typography
                  variant="h6"
                  component="div"
                  onClick={() => handleOpenServicePage(service)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {service.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {service.appGroup}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  width: "60px",
                  height: "50px",
                  backgroundColor: getScoreColor(service.score),
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  textAlign: "center",
                  padding: "5px",
                  marginTop: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                  variant="body2"
                  color={getTextColor(service.score)}
                >
                  SCORE
                </Typography>
                <Typography
                  variant="body2"
                  color={getTextColor(service.score)}
                  style={{ fontWeight: "bold" }}
                >
                  {service.score}
                </Typography>
              </Grid>
            </Grid>
          }
        />

        <CardContent>
          <Grid container spacing={2}>
            {/* Grid container with spacing between items */}
            <Grid item xs={10}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{
                  whiteSpace: "pre-line",
                  marginRight: "10px",
                  marginTop: "10px",
                }} // Added margin-right for spacing
              >
                {service.description}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <img
                src={cert}
                width="50"
                alt="cert"
                style={{ margin: "auto" }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              borderTop: "1px solid #e0e0e0",
              paddingTop: "16px",
              marginTop: "16px",
            }}
          >
            <Grid item xs={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <GitBranch
                  size={16}
                  style={{ marginRight: "8px", color: "#757575" }}
                />
                <Typography variant="body2">{service.repo}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Users
                  size={16}
                  style={{ marginRight: "8px", color: "#757575" }}
                />
                <Typography variant="body2">{service.team}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Clock
                  size={16}
                  style={{ marginRight: "8px", color: "#757575" }}
                />
                <Typography variant="body2">
                  Deployed: {service.lastDeployed}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  style={{ marginRight: "8px", color: "#757575" }}
                >
                  <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
                </svg>
                <Typography variant="body2">Tier: {service.tier}</Typography>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              borderTop: "1px solid #e0e0e0",
              justifyContent: "space-between", // Adjusts spacing between items
              marginTop: "16px",
              gap: 2,
            }}
          >
            <Grid
              item
              sx={{ display: "flex", justifyContent: "flex-start", flex: 1 }}
            >
              <Link
                className="service-card-link"
                target="_blank"
                rel="noopener"
                underline="none"
                color="textSecondary"
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
                onClick={() => handleDocs(service)}
              >
                <Book size={16} />
                <Typography variant="body2">Docs</Typography>
              </Link>
            </Grid>

            <Grid
              item
              sx={{ display: "flex", justifyContent: "center", flex: 1 }}
            >
              <Link
                className="service-card-link"
                target="_blank"
                rel="noopener"
                underline="none"
                color="textSecondary"
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
                onClick={() => handleDeployment(service)}
              >
                <GitBranch size={16} />
                <Typography variant="body2">Deployments</Typography>
              </Link>
            </Grid>

            <Grid
              item
              sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }}
            >
              <Link
                href={service.links.datadog}
                target="_blank"
                rel="noopener"
                underline="none"
                color="textSecondary"
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <LineChart size={16} />
                <Typography variant="body2">Datadog</Typography>
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
export default ServiceCard;
