import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  loading: false,
  catalog: null,
  error: '',
};

// Async action to fetch catalog data
export const getCatalog = createAsyncThunk('catalog/getCatalog', async (axiosInstance) => {
  const response = await axiosInstance.get('/catalog');
  return response.data; // This will be the payload in the fulfilled action
});

// Create the slice
const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    // Add any reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCatalog.pending, (state) => {
        state.loading = true; // Set loading to true when the request starts
        state.error = ''; // Reset the error on new request
      })
      .addCase(getCatalog.fulfilled, (state, action) => {
        state.loading = false; // Set loading to false after the request is completed
        state.catalog = action.payload; // Save the fetched catalog data
      })
      .addCase(getCatalog.rejected, (state, action) => {
        state.loading = false; // Set loading to false if the request fails
        state.error = action.error.message; // Set the error message from the action
      });
  },
});

export default catalogSlice.reducer;

// No need to explicitly export getCatalog again
