import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import { useNavigate } from "react-router-dom";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { useSelector } from "react-redux";

const secondaryListItems = [
  { text: "Settings", icon: <SettingsRoundedIcon /> },
  { text: "About", icon: <InfoRoundedIcon /> },
  { text: "Feedback", icon: <HelpRoundedIcon /> },
];

const MenuContent = ({ collapsed }) => {
  const [menuIndex, setMenuIndex] = useState(0);
  const navigate = useNavigate();
  const mainListItems = useSelector((state) => state.menu);

  const handleChangeMenuIndex = (index, path) => {
    setMenuIndex(index);
    navigate(path);
  };

  const MenuListItem = ({ item, index, handleClick, selected }) => (
    <ListItem disablePadding sx={{ display: "block" }}>
      <Tooltip title={collapsed ? item.text : ""} placement="right">
        <ListItemButton
          onClick={handleClick}
          selected={selected}
          sx={{
            minHeight: 48,
            justifyContent: collapsed ? 'center' : 'initial',
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: collapsed ? 0 : 3,
              marginRight: 0, 
              justifyContent: 'center',
              '& .MuiSvgIcon-root': { 
                width: '1.5rem', 
                height: 'auto', 
                fontSize: '1.5rem'
              }
            }}
          >
            {item.icon}
          </ListItemIcon>
          {!collapsed && (
            <ListItemText
              sx={{
                opacity: collapsed ? 0 : 1,
                "& .MuiListItemText-primary": {
                  fontWeight: 400,
                  lineHeight: 2.2
                },
              }}
              primary={item.text}
            />
          )}
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );

  return (
    <Stack sx={{ 
      flexGrow: 1, 
      p: collapsed ? 0.5 : 1, 
      justifyContent: "space-between"
    }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <MenuListItem
            key={index}
            item={item}
            index={index}
            handleClick={() => handleChangeMenuIndex(index, item.path)}
            selected={menuIndex === index}
          />
        ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <MenuListItem
            key={index}
            item={item}
            index={index}
            handleClick={() => {}}
            selected={false}
          />
        ))}
      </List>
    </Stack>
  );
};

export default MenuContent;