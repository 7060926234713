import React from "react";
import {
  Check,
  X,
  Clock,
  GitCommit,
  Package,
  Terminal,
  Rocket,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Workflow = ({ workflowType = "success" }) => {
  // Default workflow data inside the component
  const navigate = useNavigate();

  const workflowData =
    workflowType === "success"
      ? {
          id: "workflow_123",
          status: "success",
          branch: "main",
          commit: "feat: add user authentication",
          commitHash: "8f79e2e",
          triggeredBy: "john.doe",
          duration: "5m 23s",
          stages: [
            {
              name: "Setup",
              status: "success",
              duration: "45s",
              logs: "Successfully initialized environment",
            },
            {
              name: "Test",
              status: "success",
              duration: "2m 15s",
              logs: "All 127 tests passed",
            },
            {
              name: "Build",
              status: "success",
              duration: "1m 42s",
              logs: "Build successful. Artifacts generated",
            },
            {
              name: "Deploy",
              status: "success",
              duration: "52s",
              logs: "Deployed to production",
            },
          ],
        }
      : {
          id: "workflow_124",
          status: "failed",
          branch: "feature/payment",
          commit: "fix: payment gateway integration",
          commitHash: "3d4f5e2",
          triggeredBy: "jane.smith",
          duration: "3m 45s",
          stages: [
            {
              name: "Setup",
              status: "success",
              duration: "43s",
              logs: "Successfully initialized environment",
            },
            {
              name: "Test",
              status: "failed",
              duration: "2m 12s",
              logs: "Error: Payment integration test failed\nExpected status 200, got 500",
            },
            {
              name: "Build",
              status: "skipped",
              duration: "-",
              logs: "Stage skipped due to test failure",
            },
            {
              name: "Deploy",
              status: "skipped",
              duration: "-",
              logs: "Stage skipped due to test failure",
            },
          ],
        };

  const getStatusIcon = (status) => {
    switch (status) {
      case "success":
        return <Check className="h-5 w-5 text-green-500" />;
      case "failed":
        return <X className="h-5 w-5 text-red-500" />;
      case "skipped":
        return <Clock className="h-5 w-5 text-gray-400" />;
      default:
        return <Clock className="h-5 w-5 text-gray-400" />;
    }
  };

  const getStageIcon = (name) => {
    switch (name) {
      case "Setup":
        return <GitCommit className="h-5 w-5" />;
      case "Test":
        return <Terminal className="h-5 w-5" />;
      case "Build":
        return <Package className="h-5 w-5" />;
      case "Deploy":
        return <Rocket className="h-5 w-5" />;
      default:
        return <Clock className="h-5 w-5" />;
    }
  };

  const handleReturnToDeployment = () => {
    navigate("/deployment");
  };

  return (
    <div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          size="small" // Makes the button small
          startIcon={<ArrowBackIcon />}
          onClick={handleReturnToDeployment}
          sx={{
            fontSize: "0.75rem", // Adjust font size to be smaller
            padding: "4px 12px", // Make padding smaller for a compact button
          }}
        >
          Back to Deployment
        </Button>
      </div>

      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        {/* Header */}

        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-xl font-semibold flex items-center gap-2">
              Workflow #{workflowData.id}
              {getStatusIcon(workflowData.status)}
            </h2>
            <p className="text-gray-600 mt-1">
              {workflowData.commit} ({workflowData.commitHash})
            </p>
          </div>
          <div className="text-right">
            <p className="text-gray-600">Branch: {workflowData.branch}</p>
            <p className="text-gray-600">Duration: {workflowData.duration}</p>
          </div>
        </div>

        {/* Stages */}
        <div className="space-y-4">
          {workflowData.stages.map((stage, index) => (
            <div
              key={stage.name}
              className={`border rounded-lg p-4 ${
                stage.status === "success"
                  ? "border-green-200 bg-green-50"
                  : stage.status === "failed"
                  ? "border-red-200 bg-red-50"
                  : "border-gray-200 bg-gray-50"
              }`}
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  {getStageIcon(stage.name)}
                  <span className="font-medium">{stage.name}</span>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-gray-600">{stage.duration}</span>
                  {getStatusIcon(stage.status)}
                </div>
              </div>
              <pre
                className={`mt-2 p-2 rounded text-sm font-mono ${
                  stage.status === "failed"
                    ? "bg-red-100 text-red-800"
                    : "bg-gray-100 text-gray-800"
                }`}
              >
                {stage.logs}
              </pre>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Workflow;
