import React, { useEffect } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { GitBranch, ArrowRight, Clock, AlertCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const ServicePage = ({ service }) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("service", service);
  }, [service]);

  const deployments = [
    {
      id: 1,
      version: "v1.2.3",
      status: "Success",
      timestamp: "2024-01-05 14:30",
      author: "Sarah Chen",
      environment: "Production",
      cluster: "us-west-prod-01",
    },
    {
      id: 2,
      version: "v1.2.2",
      status: "Success",
      timestamp: "2024-01-03 11:20",
      author: "John Doe",
      environment: "Staging",
      cluster: "us-west-stage-01",
    },
    {
      id: 3,
      version: "v1.2.1",
      status: "Failed",
      timestamp: "2024-01-02 09:45",
      author: "Emma Wilson",
      environment: "Production",
      cluster: "us-west-prod-01",
    },
    {
      id: 4,
      version: "v1.2.0",
      status: "Success",
      timestamp: "2024-01-01 16:15",
      author: "Mike Johnson",
      environment: "QA",
      cluster: "us-west-qa-01",
    },
    {
      id: 5,
      version: "v1.1.9",
      status: "Success",
      timestamp: "2023-12-31 13:00",
      author: "Sarah Chen",
      environment: "Development",
      cluster: "us-west-dev-01",
    },
  ];

  const environments = [
    {
      name: "Production",
      status: "Healthy",
      version: "v1.2.3",
      lastDeployed: "2024-01-05 14:30",
      environment: "prod",
      cluster: "us-west-prod-01",
      region: "us-west-2",
    },
    {
      name: "Staging",
      status: "Healthy",
      version: "v1.2.3",
      lastDeployed: "2024-01-05 13:15",
      environment: "stage",
      cluster: "us-west-stage-01",
      region: "us-west-2",
    },
    {
      name: "QA",
      status: "Warning",
      version: "v1.2.4-rc1",
      lastDeployed: "2024-01-05 10:00",
      environment: "qa",
      cluster: "us-west-qa-01",
      region: "us-west-2",
    },
    {
      name: "Development",
      status: "Healthy",
      version: "v1.2.4-dev",
      lastDeployed: "2024-01-05 09:30",
      environment: "dev",
      cluster: "us-west-dev-01",
      region: "us-west-2",
    },
  ];

  const dependencies = {
    upstream: [
      { name: "User Service", status: "Healthy" },
      { name: "Permissions Service", status: "Healthy" },
    ],
    downstream: [
      { name: "Payment Service", status: "Healthy" },
      { name: "Notification Service", status: "Warning" },
      { name: "Analytics Service", status: "Healthy" },
    ],
  };

  // Generate sample metrics data
  const generateMetrics = (baseValue, variance) =>
    Array.from({ length: 24 }, (_, i) => ({
      time: `${i}:00`,
      value: baseValue + Math.random() * variance,
    }));

  const metrics = {
    cpu: generateMetrics(20, 40),
    memory: generateMetrics(40, 30),
    responseTime: generateMetrics(100, 150),
    errorCount: generateMetrics(0, 10),
  };

  const pagerduty = {
    oncall: { name: "Sarah Chen", until: "2024-01-12" },
    recent: [
      {
        id: 1,
        severity: "High",
        status: "Resolved",
        timestamp: "2024-01-04 23:15",
        description: "High latency in auth requests",
      },
      {
        id: 2,
        severity: "Critical",
        status: "Resolved",
        timestamp: "2024-01-03 15:30",
        description: "Service outage",
      },
      {
        id: 3,
        severity: "Low",
        status: "Resolved",
        timestamp: "2024-01-02 08:45",
        description: "Increased error rate",
      },
    ],
  };

  // Render metric chart
  const renderMetricChart = (data, title, color) => (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent className="h-[200px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke={color} />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );

  const handleReturnToCatalog = () => {
    navigate("/catalog");
  };

  return (
    <div className="p-6">
      <div>
        <Button
          variant="outlined"
          color="primary"
          size="small" // Makes the button small
          startIcon={<ArrowBackIcon />}
          onClick={handleReturnToCatalog}
          sx={{
            fontSize: "0.75rem", // Adjust font size to be smaller
            padding: "4px 12px", // Make padding smaller for a compact button
          }}
        >
          Back to Catalog
        </Button>
      </div>

      <Tabs defaultValue="service" className="w-full">
        <TabsList className="mb-4">
          <TabsTrigger value="service">Service</TabsTrigger>
          <TabsTrigger value="deployments">Deployments</TabsTrigger>
          <TabsTrigger value="environments">Environments</TabsTrigger>
          <TabsTrigger value="dependencies">Dependencies</TabsTrigger>
          <TabsTrigger value="monitoring">Monitoring</TabsTrigger>
          <TabsTrigger value="pagerduty">PagerDuty</TabsTrigger>
        </TabsList>

        {/* Service Info Tab */}
        <TabsContent value="service">
          <Card>
            <CardHeader>
              <CardTitle>Service Information</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="font-medium mb-2">Details</h3>
                  <div className="space-y-2 text-sm">
                    <p>
                      <span className="text-gray-500">Name:</span>{" "}
                      Authentication Service
                    </p>
                    <p>
                      <span className="text-gray-500">Team:</span> Identity Team
                    </p>
                    <p>
                      <span className="text-gray-500">Repository:</span>{" "}
                      auth-service
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="font-medium mb-2">Technical</h3>
                  <div className="space-y-2 text-sm">
                    <p>
                      <span className="text-gray-500">Framework:</span> Node.js
                    </p>
                    <p>
                      <span className="text-gray-500">Database:</span>{" "}
                      PostgreSQL
                    </p>
                    <p>
                      <span className="text-gray-500">Cache:</span> Redis
                    </p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        {/* Deployments Tab */}
        <TabsContent value="deployments">
          <Card>
            <CardHeader>
              <CardTitle>Recent Deployments</CardTitle>
              <CardDescription>Last 5 deployments</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {deployments.map((deployment) => (
                  <div
                    key={deployment.id}
                    className="flex items-center justify-between p-3 border rounded-lg"
                  >
                    <div className="flex items-center gap-3">
                      <GitBranch className="w-4 h-4 text-gray-500" />
                      <div>
                        <div className="font-medium">{deployment.version}</div>
                        <div className="text-sm text-gray-500">
                          {deployment.author}
                        </div>
                      </div>
                    </div>
                    <div className="text-sm text-gray-500">
                      {deployment.timestamp}
                    </div>
                    <div
                      className={`px-2 py-1 rounded-full text-xs ${
                        deployment.status === "Success"
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {deployment.status}
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        {/* Environments Tab */}
        <TabsContent value="environments">
          <Card>
            <CardHeader>
              <CardTitle>Environments</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {environments.map((env) => (
                  <div
                    key={env.name}
                    className="flex items-center justify-between p-3 border rounded-lg"
                  >
                    <div>
                      <div className="font-medium">{env.name}</div>
                      <div className="text-sm text-gray-500">
                        Version: {env.version}
                      </div>
                    </div>
                    <div className="text-sm text-gray-500">
                      <Clock className="w-4 h-4 inline mr-1" />
                      {env.lastDeployed}
                    </div>
                    <div
                      className={`px-2 py-1 rounded-full text-xs ${
                        env.status === "Healthy"
                          ? "bg-green-100 text-green-800"
                          : "bg-yellow-100 text-yellow-800"
                      }`}
                    >
                      {env.status}
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        {/* Dependencies Tab */}
        <TabsContent value="dependencies">
          <div className="grid gap-4">
            <Card>
              <CardHeader>
                <CardTitle>Upstream Dependencies</CardTitle>
                <CardDescription>
                  Services this service depends on
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-2">
                  {dependencies.upstream.map((dep) => (
                    <div
                      key={dep.name}
                      className="flex items-center justify-between p-2 border rounded"
                    >
                      <span className="font-medium">{dep.name}</span>
                      <ArrowRight className="w-4 h-4 text-gray-400" />
                      <span
                        className={`px-2 py-1 rounded-full text-xs ${
                          dep.status === "Healthy"
                            ? "bg-green-100 text-green-800"
                            : "bg-yellow-100 text-yellow-800"
                        }`}
                      >
                        {dep.status}
                      </span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Downstream Dependencies</CardTitle>
                <CardDescription>
                  Services that depend on this service
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-2">
                  {dependencies.downstream.map((dep) => (
                    <div
                      key={dep.name}
                      className="flex items-center justify-between p-2 border rounded"
                    >
                      <span className="font-medium">{dep.name}</span>
                      <ArrowRight className="w-4 h-4 text-gray-400" />
                      <span
                        className={`px-2 py-1 rounded-full text-xs ${
                          dep.status === "Healthy"
                            ? "bg-green-100 text-green-800"
                            : "bg-yellow-100 text-yellow-800"
                        }`}
                      >
                        {dep.status}
                      </span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </TabsContent>

        {/* Monitoring Tab */}
        <TabsContent value="monitoring">
          <div className="space-y-6">
            {/* KPIs */}
            <div className="grid grid-cols-4 gap-4">
              <Card>
                <CardContent className="pt-6">
                  <div className="flex flex-col items-center space-y-1">
                    <span className="text-sm font-medium text-gray-500">
                      Success Rate
                    </span>
                    <span className="text-2xl font-bold text-green-600">
                      99.9%
                    </span>
                    <span className="text-xs text-green-600">↑ 0.2%</span>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardContent className="pt-6">
                  <div className="flex flex-col items-center space-y-1">
                    <span className="text-sm font-medium text-gray-500">
                      Avg Response Time
                    </span>
                    <span className="text-2xl font-bold text-amber-600">
                      142ms
                    </span>
                    <span className="text-xs text-red-600">↑ 12ms</span>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardContent className="pt-6">
                  <div className="flex flex-col items-center space-y-1">
                    <span className="text-sm font-medium text-gray-500">
                      Error Rate
                    </span>
                    <span className="text-2xl font-bold text-green-600">
                      0.1%
                    </span>
                    <span className="text-xs text-green-600">↓ 0.05%</span>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardContent className="pt-6">
                  <div className="flex flex-col items-center space-y-1">
                    <span className="text-sm font-medium text-gray-500">
                      Requests/min
                    </span>
                    <span className="text-2xl font-bold">1,240</span>
                    <span className="text-xs text-gray-500">↔ Stable</span>
                  </div>
                </CardContent>
              </Card>
            </div>

            {/* Charts */}
            <div className="grid grid-cols-2 gap-4">
              {renderMetricChart(metrics.cpu, "CPU Usage", "#8884d8")}
              {renderMetricChart(metrics.memory, "Memory Usage", "#82ca9d")}
              {renderMetricChart(
                metrics.responseTime,
                "Response Time (ms)",
                "#ffc658"
              )}
              {renderMetricChart(metrics.errorCount, "Error Count", "#ff7300")}
            </div>

            {/* Logs */}
            <Card>
              <CardHeader>
                <CardTitle>Recent Logs</CardTitle>
                <CardDescription>Last 10 log entries</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-2 max-h-[300px] overflow-auto">
                  {[
                    {
                      timestamp: "2024-01-05 15:42:33",
                      level: "INFO",
                      message: "Request processed successfully",
                      service: "auth-handler",
                    },
                    {
                      timestamp: "2024-01-05 15:42:31",
                      level: "WARN",
                      message: "High latency detected in database query",
                      service: "db-connection",
                    },
                    {
                      timestamp: "2024-01-05 15:42:30",
                      level: "ERROR",
                      message: "Failed to connect to cache server",
                      service: "redis-client",
                    },
                    {
                      timestamp: "2024-01-05 15:42:28",
                      level: "INFO",
                      message: "User session created",
                      service: "session-manager",
                    },
                    {
                      timestamp: "2024-01-05 15:42:25",
                      level: "INFO",
                      message: "Cache updated successfully",
                      service: "cache-handler",
                    },
                    {
                      timestamp: "2024-01-05 15:42:22",
                      level: "DEBUG",
                      message: "Processing auth request",
                      service: "auth-handler",
                    },
                    {
                      timestamp: "2024-01-05 15:42:20",
                      level: "INFO",
                      message: "Service health check passed",
                      service: "health-monitor",
                    },
                    {
                      timestamp: "2024-01-05 15:42:18",
                      level: "WARN",
                      message: "Rate limit threshold approaching",
                      service: "rate-limiter",
                    },
                    {
                      timestamp: "2024-01-05 15:42:15",
                      level: "INFO",
                      message: "Configuration reloaded",
                      service: "config-manager",
                    },
                    {
                      timestamp: "2024-01-05 15:42:12",
                      level: "DEBUG",
                      message: "Cleaning expired sessions",
                      service: "session-manager",
                    },
                  ].map((log, index) => (
                    <div
                      key={index}
                      className="flex items-start space-x-3 text-sm p-2 hover:bg-gray-50 rounded"
                    >
                      <span className="text-gray-500 min-w-[160px]">
                        {log.timestamp}
                      </span>
                      <span
                        className={`px-2 py-0.5 rounded text-xs font-medium ${
                          log.level === "ERROR"
                            ? "bg-red-100 text-red-800"
                            : log.level === "WARN"
                            ? "bg-yellow-100 text-yellow-800"
                            : log.level === "INFO"
                            ? "bg-blue-100 text-blue-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {log.level}
                      </span>
                      <span className="flex-1">{log.message}</span>
                      <span className="text-gray-500">{log.service}</span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </TabsContent>

        {/* PagerDuty Tab */}
        <TabsContent value="pagerduty">
          <div className="grid gap-4">
            <Card>
              <CardHeader>
                <CardTitle>On-Call</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex items-center justify-between p-3 border rounded-lg">
                  <div>
                    <div className="font-medium">{pagerduty.oncall.name}</div>
                    <div className="text-sm text-gray-500">
                      Until {pagerduty.oncall.until}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Recent Incidents</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {pagerduty.recent.map((incident) => (
                    <div
                      key={incident.id}
                      className="flex items-center justify-between p-3 border rounded-lg"
                    >
                      <div className="flex items-center gap-3">
                        <AlertCircle className="w-4 h-4 text-gray-500" />
                        <div>
                          <div className="font-medium">
                            {incident.description}
                          </div>
                          <div className="text-sm text-gray-500">
                            {incident.timestamp}
                          </div>
                        </div>
                      </div>
                      <span
                        className={`px-2 py-1 rounded-full text-xs ${
                          incident.severity === "Critical"
                            ? "bg-red-100 text-red-800"
                            : incident.severity === "High"
                            ? "bg-orange-100 text-orange-800"
                            : "bg-yellow-100 text-yellow-800"
                        }`}
                      >
                        {incident.severity}
                      </span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};
export default ServicePage;
