import React, { useState } from "react";
import {
  Settings as SettingsIcon,
  AlertCircle as AlertCircleIcon,
  Save as SaveIcon,
  ChevronRight as ChevronRightIcon,
  CalendarToday as CalendarIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Input,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  Badge,
  Alert,
  AlertTitle,
  FormControl,
  InputLabel,
  Grid2,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const DeploymentConfiguration = () => {
  const [selectedWorkflow, setSelectedWorkflow] = useState("standard");
  const [enableTimeWindow, setEnableTimeWindow] = useState(true);
  const [selectedDays, setSelectedDays] = useState([
    "monday",
    "wednesday",
    "friday",
  ]);
  const [notifyOnFailure, setNotifyOnFailure] = useState(true);
  const navigate = useNavigate();

  const workflows = [
    {
      id: "standard",
      name: "Standard Release",
      description: "Development → Staging → Production",
      steps: ["Build", "Test", "Stage", "Deploy"],
    },
    {
      id: "hotfix",
      name: "Hotfix",
      description: "Direct to Production with approval",
      steps: ["Build", "Test", "Deploy"],
    },
    {
      id: "blue-green",
      name: "Blue-Green",
      description: "Zero-downtime deployment with switchover",
      steps: ["Build", "Test", "Deploy Blue", "Switch", "Verify"],
    },
  ];

  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const timeWindows = [
    {
      id: 1,
      start: "09:00",
      end: "17:00",
      days: ["monday", "wednesday", "friday"],
    },
    { id: 2, start: "10:00", end: "16:00", days: ["tuesday", "thursday"] },
  ];

  const handleReturnToDeployment = () => {
    navigate("/deployment");
  };

  return (
    <div className="mx-auto p-8">
      <div className="flex justify-between items-center mb-8">
        <div>
          <Button
            variant="outlined"
            color="primary"
            size="small" // Makes the button small
            startIcon={<ArrowBackIcon />}
            onClick={handleReturnToDeployment}
            sx={{
              fontSize: "0.75rem", // Adjust font size to be smaller
              padding: "4px 12px", // Make padding smaller for a compact button
            }}
          >
            Back to Deployment
          </Button>
        </div>
        <div>
          <Typography variant="h5" component="h1" sx={{ fontWeight: "bold" }}>
            Deployment Configuration
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Configure deployment workflows and release windows
          </Typography>
        </div>
        <Button variant="contained" color="primary" startIcon={<SaveIcon />}>
          Save Changes
        </Button>
      </div>

   <Grid2 container spacing={2.5}>
  <Grid2 item xs={12} sm={6} md={4} sx={{ height: '470px', width:'470px' }}>
    <Card
      sx={{
        display: 'flex', // Make the card a flex container
        flexDirection: 'column', // Stack content vertically
        height: '100%', // Ensure the card takes up the full height of the grid item
        border: 1,
        borderColor: 'divider',
        ":hover": { borderColor: 'orange' },
        boxShadow: 2,
      }}
    >
      <CardHeader
        title={
          <Typography
            variant="h6"
            component="div"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <CalendarIcon sx={{ color: 'orange', mr: 1 }} />
            Release Windows
          </Typography>
        }
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <div className="space-y-4">
          {workflows.map((workflow) => (
            <div
              key={workflow.id}
              className={`p-4 rounded-lg border-2 cursor-pointer transition-all ${
                selectedWorkflow === workflow.id
                  ? 'border-orange-500 bg-orange-50'
                  : 'border-gray-100 hover:border-gray-200'
              }`}
              onClick={() => setSelectedWorkflow(workflow.id)}
            >
              <div className="flex items-start justify-between mb-2">
                <div>
                  <Typography variant="body1" fontWeight="medium">
                    {workflow.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {workflow.description}
                  </Typography>
                </div>
                {selectedWorkflow === workflow.id && (
                  <Badge color="primary" variant="filled">
                    Selected
                  </Badge>
                )}
              </div>
              <div className="flex items-center gap-2 mt-3">
                {workflow.steps.map((step, index) => (
                  <React.Fragment key={step}>
                    <Typography
                      variant="caption"
                      sx={{
                        backgroundColor: 'grey.100',
                        paddingX: 1,
                        paddingY: 0.5,
                        borderRadius: 2,
                      }}
                    >
                      {step}
                    </Typography>
                    {index < workflow.steps.length - 1 && (
                      <ChevronRightIcon sx={{ color: 'grey.400', fontSize: 16 }} />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  </Grid2>

  <Grid2 item xs={12} sm={6} md={4} sx={{ height: '470px' , width:'470px'}}>
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        border: 1,
        borderColor: 'divider',
        ":hover": { borderColor: 'orange' },
        boxShadow: 2,
      }}
    >
      <CardHeader
        title={
          <Typography
            variant="h6"
            component="div"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <SettingsIcon sx={{ color: 'orange', mr: 1 }} />
            Deployment Workflow
          </Typography>
        }
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <div className="space-y-4">
          <FormControlLabel
            control={
              <Switch
                checked={enableTimeWindow}
                onChange={(e) => setEnableTimeWindow(e.target.checked)}
              />
            }
            label="Time Window"
            labelPlacement="start"
          />
          <Typography variant="body2" color="textSecondary">
            Restrict deployment times
          </Typography>

          <FormControlLabel
            control={
              <Switch
                checked={notifyOnFailure}
                onChange={(e) => setNotifyOnFailure(e.target.checked)}
              />
            }
            label="Failure Notifications"
            labelPlacement="start"
          />
          <Typography variant="body2" color="textSecondary">
            Alert team on failures
          </Typography>

          <FormControl fullWidth>
            <InputLabel>Auto Rollback</InputLabel>
            <Select defaultValue="manual">
              <MenuItem value="never">Never</MenuItem>
              <MenuItem value="manual">Manual approval</MenuItem>
              <MenuItem value="auto">Automatic on failure</MenuItem>
            </Select>
          </FormControl>
        </div>
      </CardContent>
    </Card>
  </Grid2>

  <Grid2 item xs={12} sm={6} md={4} sx={{ height: '470px', width:'470px' }}>
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        border: 1,
        borderColor: 'divider',
        ":hover": { borderColor: 'orange' },
        boxShadow: 2,
      }}
    >
      <CardHeader
        title={
          <Typography
            variant="h6"
            component="div"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <AccessTimeIcon sx={{ color: 'orange', mr: 1 }} />
            Quick Settings
          </Typography>
        }
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <div className="space-y-4">
          {/* Time Windows */}
          <div className="space-y-4">
            {timeWindows.map((window) => (
              <div
                key={window.id}
                className="flex items-center gap-6 p-4 rounded-lg border border-gray-100"
              >
                <div className="flex-1">
                  <div className="flex items-center gap-4 mb-2">
                    <Input
                      type="time"
                      defaultValue={window.start}
                      sx={{ width: '80px' }}
                    />
                    <span className="text-gray-500">to</span>
                    <Input
                      type="time"
                      defaultValue={window.end}
                      sx={{ width: '80px' }}
                    />
                  </div>
                  <div className="flex gap-2">
                    {days.map((day) => (
                      <button
                        key={day}
                        className={`text-xs px-2 py-1 rounded-full ${
                          window.days.includes(day)
                            ? 'bg-orange-500 text-white'
                            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                        }`}
                      >
                        {day.slice(0, 3)}
                      </button>
                    ))}
                  </div>
                </div>
                <Button variant="text" color="error" size="small" sx={{ padding: 0 }}>
                  <DeleteIcon sx={{ fontSize: 16 }} />
                </Button>
              </div>
            ))}
          </div>

          {/* Add New Window */}
          <Button variant="outlined" fullWidth startIcon={<AddIcon />}>
            Add Release Window
          </Button>

          {/* Warning */}
          <Alert severity="warning" sx={{ bgcolor: 'orange.50', borderColor: 'orange.200' }}>
            <AlertTitle>Warning</AlertTitle>
            Deployments outside release windows will require manual approval.
          </Alert>
        </div>
      </CardContent>
    </Card>
  </Grid2>
</Grid2>

    </div>
  );
};

export default DeploymentConfiguration;
