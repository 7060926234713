import React, { useEffect } from "react";
import './Profile.scss';
import { useSelector } from "react-redux";
import  profilePicture  from '../../images/profile.png'


const Profile = () => {
    const userInfo = useSelector((state) => state.user.userInfo)


  
    return (
        <div className="profile-container">
          <div className="profile-card">
            <div className="profile-picture">
              <img src={profilePicture} alt="Profile" />
            </div>
            <div className="profile-details">
              <h2>{userInfo?.name}</h2>
              <p>{userInfo?.email}</p>
            </div>
          </div>
        </div>
      )
};

export default Profile;
