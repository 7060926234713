import React, { useState } from "react";
import "./Roles.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Box,
} from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";

const Roles = ({
  handleAdd,
  setNewItem,
  selectedTab,
  newItem,
  handleSaveNewItem,
  handleCancelEdit,
  permissionTypes,
  editingRoleId,
  setEditingRoleId,
  editedRole,
  setEditedRole,
  setRoles,
  roles,
}) => {
  const handleEditRole = (role) => {
    setEditingRoleId(role.id);
    console.log("role.id", role.id);
    setEditedRole({ ...role }); // Copy the permission to avoid direct mutation
  };

  const handleSaveRoleEdit = () => {
    setRoles(roles.map((r) => (r.id === editingRoleId.id ? editingRoleId : r)));
    setEditingRoleId(null);
    setEditedRole(null);
  };

  const handleCancelRoleEdit = () => {
    setEditingRoleId(null);
    setEditedRole(null);
  };

  return (
    <div className="space-y-4">
      <Button
        onClick={() => handleAdd("roles")}
        startIcon={<Add />}
        variant="outlined"
        sx={{
          borderColor: "#E6863C",
          color: "#E6863C",
          "&:hover": {
            backgroundColor: "#E6863C",
            color: "white",
            borderColor: "#E6863C",
          },
          marginBottom: 2,
        }}
      >
        Add Role
      </Button>

      {newItem && selectedTab === "roles" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Role Name"
            value={newItem.name}
            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
            fullWidth
            size="small"
            sx={{ maxWidth: 300 }}
          />
          <TextField
            label="Description"
            value={newItem.description}
            onChange={(e) =>
              setNewItem({ ...newItem, description: e.target.value })
            }
            fullWidth
            size="small"
            sx={{ maxWidth: 300 }}
          />
          <FormControl fullWidth size="small" sx={{ maxWidth: 300 }}>
            <InputLabel>Permissions</InputLabel>
            <Select
              value={newItem.permissions[0]}
              onChange={(e) =>
                setNewItem({ ...newItem, permissions: [e.target.value] })
              }
              size="small"
              sx={{
                backgroundColor: "transparent",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "transparent",
                },
                "& .MuiSelect-icon": {
                  color: "#000",
                },
              }}
            >
              {permissionTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor: "black", // Set the background color to #E6863C
                "&:hover": {
                  backgroundColor: "black", // Set a hover color (a darker shade of #E6863C)
                },
                color: "white", // Set text color to white
              }}
              onClick={handleSaveNewItem}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleCancelEdit}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Permissions</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role) => (
              <TableRow
                key={role.id}
                sx={{ backgroundColor: "#fff", borderBottom: "1px solid #ddd" }}
              >
                <TableCell>
                  {editingRoleId === role.id ? (
                    <TextField
                      value={editedRole.name}
                      onChange={(e) =>
                        setEditedRole({ ...editedRole, name: e.target.value })
                      }
                      size="small"
                      fullWidth
                    />
                  ) : (
                    role.name
                  )}
                </TableCell>
                <TableCell>
                  {editingRoleId === role.id ? (
                    <TextField
                      value={editedRole.description}
                      onChange={(e) =>
                        setEditedRole({
                          ...editedRole,
                          description: e.target.value,
                        })
                      }
                      size="small"
                      fullWidth
                    />
                  ) : (
                    role.description
                  )}
                </TableCell>
                <TableCell>
                  {editingRoleId === role.id ? (
                    <Select
                      value={editedRole.permissions[0]}
                      onChange={(e) =>
                        setEditedRole({
                          ...editedRole,
                          permissions: [e.target.value],
                        })
                      }
                      size="small"
                      fullWidth
                    >
                      {permissionTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    role.permissions.join(", ")
                  )}
                </TableCell>
                <TableCell>
                  {editingRoleId === role.id ? (
                    <Box sx={{ display: "flex", gap: 1, float: "right" }}>
                      <Button
                        sx={{
                          fontSize: "0.75rem",
                          backgroundColor: "black", // Set the background color to #E6863C
                          "&:hover": {
                            backgroundColor: "black", // Set a hover color (a darker shade of #E6863C)
                          },
                          color: "white", // Set text color to white
                        }}
                        onClick={handleSaveRoleEdit}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={handleCancelRoleEdit}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", gap: 1, float: "right" }}>
                      <IconButton
                        onClick={() => handleEditRole(role)}
                        size="small"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() =>
                          setRoles(roles.filter((r) => r.id !== role.id))
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Roles;
