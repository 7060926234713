import React from "react";
import ServiceDocs from "../Catalog/ServiceDocs";



const Documentation = () => {
  

  return (
        <ServiceDocs />
  );
};

export default Documentation;
