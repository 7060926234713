import React, { useEffect } from "react";
import "./Catalog.scss";
import { useDispatch, useSelector } from "react-redux";
import ServiceCard from "./ServiceCard";
import { Grid, Paper } from "@mui/material";
import FilterServices from "./FilterServices";

const Catalog = () => {
  const dispatch = useDispatch();
  const { catalog, loading, error } = useSelector((state) => state.catalog);
  const services = [
    {
      id: "auth-001",
      name: "Authentication Service",
      description:
        "Handles user authentication and authorization. Provides secure login",
      appGroup: "Identity & Access",
      team: "Security Team",
      lastDeployed: "2024-12-29 14:30",
      status: "healthy",
      /*  language: "TypeScript",
      version: "v2.3.0",
      category: "Security",*/
      repo: "auth-service",
      tier: "Critical",
      score: 85,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "order-001",
      name: "Order Management",
      description:
        "Processes and manages customer orders, handles order lifecycle and status updates.",
      appGroup: "Identity & Access",
      team: "Orders Team",
      lastDeployed: "2024-12-30 10:15",
      status: "healthy",
      /*   language: "Java",
      version: "v3.1.0",
      category: "Core",*/
      repo: "auth-service",
      tier: "Critical",
      score: 85,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "menu-001",
      name: "Menu Service",
      description:
        "Manages restaurant menus, pricing, and availability. Handles menu updates.",
      appGroup: "Identity & Access",
      team: "Restaurant Team",
      lastDeployed: "2024-12-30 08:45",
      status: "warning",
      /*  language: "Python",
      version: "v1.8.5",
      category: "Restaurant",*/
      repo: "auth-service",
      tier: "Critical",
      score: 85,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "delivery-001",
      name: "Delivery Tracking",
      description:
        "Tracks delivery status, manages courier assignments and route optimization.",
      appGroup: "Identity & Access",
      team: "Logistics Team",
      lastDeployed: "2024-12-29 16:20",
      status: "healthy",
      /* language: "Go",
      version: "v2.4.1",
      category: "Logistics",*/
      repo: "auth-service",
      tier: "Critical",
      score: 85,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "payment-001",
      name: "Payment Processing",
      description:
        "Handles payment processing, refunds, and financial transactions.",
      appGroup: "Identity & Access",
      team: "Payments Team",
      lastDeployed: "2024-12-30 09:30",
      status: "degraded",
      /*language: "Java",
      version: "v4.0.2",
      category: "Financial",*/
      repo: "auth-service",
      tier: "Critical",
      score: 85,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "rating-001",
      name: "Rating & Reviews",
      description:
        "Manages customer ratings and reviews for restaurants and delivery experience.",
      appGroup: "Identity & Access",
      team: "Customer Experience",
      lastDeployed: "2024-12-28 15:10",
      status: "healthy",
      /* language: "Node.js",
      version: "v1.2.0",
      category: "Customer",*/
      repo: "auth-service",
      tier: "Critical",
      score: 85,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "promo-001",
      name: "Promotions Engine",
      description:
        "Handles promotional offers, discounts, and campaign management.",
      appGroup: "Identity & Access",
      team: "Marketing Team",
      lastDeployed: "2024-12-30 11:20",
      status: "healthy",
      /* language: "Python",
      version: "v2.1.3",
      category: "Marketing",*/
      repo: "auth-service",
      tier: "Critical",
      score: 85,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "notification-001",
      name: "Notification Service",
      description: "Manages push notifications, SMS, and email communications.",
      appGroup: "Identity & Access",
      team: "Communications",
      lastDeployed: "2024-12-29 13:45",
      status: "warning",
      /*language: "TypeScript",
      version: "v1.5.0",
      category: "Communication",*/
      repo: "auth-service",
      tier: "Critical",
      score: 70,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "auth-001",
      name: "Authentication Service",
      description:
        "Handles user authentication and authorization. Provides secure login",
      appGroup: "Identity & Access",
      team: "Security Team",
      lastDeployed: "2024-12-29 14:30",
      status: "healthy",
      /*  language: "TypeScript",
      version: "v2.3.0",
      category: "Security",*/
      repo: "auth-service",
      tier: "Critical",
      score: 80,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "order-001",
      name: "Order Management",
      description:
        "Processes and manages customer orders, handles order lifecycle and status updates.",
      appGroup: "Identity & Access",
      team: "Orders Team",
      lastDeployed: "2024-12-30 10:15",
      status: "healthy",
      /*   language: "Java",
      version: "v3.1.0",
      category: "Core",*/
      repo: "auth-service",
      tier: "Critical",
      score: 85,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "menu-001",
      name: "Menu Service",
      description:
        "Manages restaurant menus, pricing, and availability. Handles menu updates.",
      appGroup: "Identity & Access",
      team: "Restaurant Team",
      lastDeployed: "2024-12-30 08:45",
      status: "warning",
      /*  language: "Python",
      version: "v1.8.5",
      category: "Restaurant",*/
      repo: "auth-service",
      tier: "Critical",
      score: 85,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "delivery-001",
      name: "Delivery Tracking",
      description:
        "Tracks delivery status, manages courier assignments and route optimization.",
      appGroup: "Identity & Access",
      team: "Logistics Team",
      lastDeployed: "2024-12-29 16:20",
      status: "healthy",
      /* language: "Go",
      version: "v2.4.1",
      category: "Logistics",*/
      repo: "auth-service",
      tier: "Critical",
      score: 98,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "payment-001",
      name: "Payment Processing",
      description:
        "Handles payment processing, refunds, and financial transactions.",
      appGroup: "Identity & Access",
      team: "Payments Team",
      lastDeployed: "2024-12-30 09:30",
      status: "degraded",
      /*language: "Java",
      version: "v4.0.2",
      category: "Financial",*/
      repo: "auth-service",
      tier: "Critical",
      score: 85,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "rating-001",
      name: "Rating & Reviews",
      description:
        "Manages customer ratings and reviews for restaurants and delivery experience.",
      appGroup: "Identity & Access",
      team: "Customer Experience",
      lastDeployed: "2024-12-28 15:10",
      status: "healthy",
      /* language: "Node.js",
      version: "v1.2.0",
      category: "Customer",*/
      repo: "auth-service",
      tier: "Critical",
      score: 70,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "promo-001",
      name: "Promotions Engine",
      description:
        "Handles promotional offers, discounts, and campaign management.",
      appGroup: "Identity & Access",
      team: "Marketing Team",
      lastDeployed: "2024-12-30 11:20",
      status: "healthy",
      /* language: "Python",
      version: "v2.1.3",
      category: "Marketing",*/
      repo: "auth-service",
      tier: "Critical",
      score: 85,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
    {
      id: "notification-001",
      name: "Notification Service",
      description: "Manages push notifications, SMS, and email communications.",
      appGroup: "Identity & Access",
      team: "Communications",
      lastDeployed: "2024-12-29 13:45",
      status: "warning",
      /*language: "TypeScript",
      version: "v1.5.0",
      category: "Communication",*/
      repo: "auth-service",
      tier: "Critical",
      score: 70,
      sonicReady: true,
      links: {
        docs: "https://docs.example.com/auth",
        deployments: "https://deployments.example.com/auth",
        datadog: "https://datadog.example.com/auth",
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FilterServices />
      </Grid>
      {services.map((service) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={service.id}>
          <ServiceCard service={service} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Catalog;
