import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import PersonIcon from "@mui/icons-material/Person";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { keyframes } from "@mui/material/styles";
import { sendMessage } from "./chatSlice";
import useAxios from "../../hooks/useAxios";

const PRIMARY_COLOR = "#E6863C";
const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;
const MessageBubble = styled(Paper)(({ isUser }) => ({
  padding: "12px 16px",
  maxWidth: "70%",
  width: "fit-content",
  marginBottom: "12px",
  backgroundColor: isUser ? PRIMARY_COLOR : "#f5f5f5",
  color: isUser ? "#fff" : "#000",
  borderRadius: isUser ? "20px 20px 4px 20px" : "20px 20px 20px 4px",
  boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
}));

const MessageContainer = styled(Box)(({ isUser }) => ({
  display: "flex",
  alignItems: "flex-start",
  gap: "12px",
  flexDirection: isUser ? "row-reverse" : "row",
  marginBottom: "20px",
}));

const ChatContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "calc(100vh - 100px)",
  /*backgroundColor: "#fff",*/
  backgroundColor: "#FCFCFC",
  position: "relative",
});

const ChatBody = styled(Box)({
  flexGrow: 1,
  overflowY: "auto",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "3px",
  },
});

const ChatFooter = styled(Box)({
  // Changed from Paper to Box
  padding: "12px 20px",
  backgroundColor: "#fff",
});

const InputContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px",
});

const StyledAvatar = styled(Avatar)(({ isUser }) => ({
  backgroundColor: isUser ? PRIMARY_COLOR : "#6E7379",
  width: 36,
  height: 36,
}));

const SendButton = styled(IconButton)(({ isloading }) => ({
  color: PRIMARY_COLOR,
  transition: "all 0.3s ease",
  "&.Mui-disabled": {
    color: isloading === "true" ? PRIMARY_COLOR : "rgba(0, 0, 0, 0.26)",
  },
}));

const LoadingSpinner = styled(CircularProgress)({
  color: PRIMARY_COLOR,
});

const TypingCursor = styled("span")({
  display: "inline-block",
  width: "2px",
  height: "15px",
  backgroundColor: "#000",
  marginLeft: "2px",
  animation: `${blinkAnimation} 1s step-end infinite`,
});

const Chat = () => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [displayedText, setDisplayedText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const userInfo = useSelector((state) => state.user.userInfo);
  const instance = useAxios(
    "https://dba-jetkb-dev-infra.internal.tenbis.cloud/api"
  );
  const [streamArray, setStreamArray] = useState([]);

  const [index, setIndex] = useState(0);
  const [openTags, setOpenTags] = useState([]);

  useEffect(() => {
    if (streamArray.length > 0) {
      console.log("streamArray", streamArray);
    }
  }, [streamArray]);

  useEffect(() => {
    if (index === streamArray.length && openTags.length > 0) {
      let closingTags = "";
      openTags.reverse().forEach((tag) => {
        closingTags += `</${tag}>`;
      });
      setDisplayedText((prev) => prev + closingTags);
    }
  }, [index, streamArray.length, openTags]);

  useEffect(() => {
    if (index < streamArray.length) {
      const timeout = setTimeout(() => {
        const currentChar = streamArray[index];
        if (currentChar === "<" && streamArray[index + 1] !== "/") {
          const tag = streamArray
            .slice(index + 1, streamArray.indexOf(">", index))
            .join("")
            .split(" ")[0];
          if (!isSelfClosingTag(tag)) {
            setOpenTags((prev) => [...prev, tag]);
          }
        } else if (currentChar === "<" && streamArray[index + 1] === "/") {
          const tag = streamArray
            .slice(index + 2, streamArray.indexOf(">", index))
            .join("")
            .split(" ")[0];
          setOpenTags((prev) => prev.filter((openTag) => openTag !== tag));
        }
        setDisplayedText((prev) => prev + currentChar);
        setIndex((prev) => prev + 1);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [index, streamArray]);

  const isSelfClosingTag = (tag) => {
    const selfClosingTags = [
      "area",
      "base",
      "br",
      "col",
      "embed",
      "hr",
      "img",
      "input",
      "link",
      "meta",
      "source",
      "track",
      "wbr",
    ];
    return selfClosingTags.includes(tag);
  };

  const animateText = async (text, delay = 30) => {
    setIsTyping(true);
    let currentText = "";

    for (let i = 0; i < text.length; i++) {
      currentText += text[i];
      setDisplayedText(currentText);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }

    setIsTyping(false);
    return currentText;
  };

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      return "good morning ☀️";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "good afternoon 🌞";
    } else if (currentHour >= 18 && currentHour < 21) {
      return "good evening 🌇";
    } else {
      return "good night 🌙";
    }
  };

  const getUserNmae = () => {
    if (userInfo && userInfo?.name) {
      return userInfo?.name;
    }
    return "";
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setMessages([]);
    const userMessage = {
      id: Date.now(),
      text:
        "Hello " +
        getUserNmae() +
        ", " +
        getGreeting() +
        ", how can i help you today?",
      isUser: false,
      avatar: "B",
    };
    setMessages((prev) => [...prev, userMessage]);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const extractMessageData = (responseString) => {
    const lines = responseString.trim().split("\n"); // Split the response into lines
    for (const line of lines) {
      try {
        const parsed = JSON.parse(line); // Parse each line as JSON
        if (parsed.type === "message") {
          return parsed.data; // Return the `data` field if `type` is `message`
        } else if (parsed.type === "token") {
          setStreamArray((prevArray) => [...prevArray, ...parsed.data]);
        }
      } catch (error) {
        console.error("Failed to parse line as JSON:", line, error);
      }
    }
    return null; // Return null if no message type is found
  };

  const handleSendMessage = async () => {
    if (!inputValue.trim() || isLoading) return;

    const userMessage = {
      id: Date.now(),
      text: inputValue,
      isUser: true,
    };

    setMessages((prev) => [...prev, userMessage]);
    setInputValue("");
    setIsLoading(true);

    try {
      //await new Promise((resolve) => setTimeout(resolve, 1000));

      //dispatch(sendMessage(instance,inputValue))

      try {
        const result = await dispatch(
          sendMessage({ axiosInstance: instance, message: inputValue })
        ).unwrap();
        console.log("result-result", result);
        let item = extractMessageData(result);
        console.log("item-item", item);
        if (item) {
          const botResponse = item.content;
          const botMessage = {
            id: Date.now() + 1,
            text: botResponse,
            isUser: false,
            isTyping: true,
          };

          setMessages((prev) => [...prev, botMessage]);
          await animateText(botResponse);

          setMessages((prev) =>
            prev.map((msg) =>
              msg.id === botMessage.id ? { ...msg, isTyping: false } : msg
            )
          );
        }
        // setResponse(result); // Set the successful response
      } catch (err) {
        // setError(err); // Handle the error
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const MessageContent = ({ message }) => {
    if (message.isUser) {
      return message.text;
    }
    
    

    if (message.isTyping) {
      return (
        <>
          <div dangerouslySetInnerHTML={{ __html: displayedText }}></div>
          <TypingCursor />
        </>
      );
      /*return ( <div dangerouslySetInnerHTML={{ __html: displayedText }}></div> )*/
    }

    return <div dangerouslySetInnerHTML={{ __html: message.text }} />;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={12} lg={8} xl={6}>
        <ChatContainer>
          {/*<ChatBody>
            {messages.map((message) => (
              <MessageContainer key={message.id} isUser={message.isUser}>
                <StyledAvatar isUser={message.isUser}>
                  {message.isUser ? <PersonIcon /> : <SmartToyIcon />}
                </StyledAvatar>
                <MessageBubble isUser={message.isUser} elevation={0}>
                  {message.text}
                </MessageBubble>
              </MessageContainer>
            ))}
            <div ref={messagesEndRef} />
          </ChatBody>*/}
          <ChatBody>
            {messages.map((message) => (
              <MessageContainer key={message.id} isUser={message.isUser}>
                <StyledAvatar isUser={message.isUser}>
                  {message.isUser ? <PersonIcon /> : <SmartToyIcon />}
                </StyledAvatar>
                <MessageBubble isUser={message.isUser} elevation={0}>
                  <MessageContent message={message} />
                </MessageBubble>
              </MessageContainer>
            ))}
            <div ref={messagesEndRef} />
          </ChatBody>

          <ChatFooter>
            <InputContainer>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Type a message..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                multiline
                maxRows={4}
                size="medium"
                disabled={isLoading}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: PRIMARY_COLOR,
                    },
                  },
                }}
              />
              <SendButton
                onClick={handleSendMessage}
                disabled={!inputValue.trim() || isLoading}
                isloading={isLoading.toString()}
                sx={{
                  width: 40,
                  height: 40,
                  position: "relative",
                }}
              >
                {isLoading ? <LoadingSpinner size={24} /> : <SendIcon />}
              </SendButton>
            </InputContainer>
          </ChatFooter>
        </ChatContainer>
      </Grid>
    </Grid>
  );
};

export default Chat;
