import * as React from "react";
import { cn } from "../lib/utils";

// Input component
const Input = React.forwardRef(({ className, ...props }, ref) => (
  <input
    ref={ref}
    className={cn(
      "px-4 py-2 text-sm rounded-md border border-muted focus:ring-2 focus:ring-primary focus:outline-none",
      className
    )}
    {...props}
  />
));
Input.displayName = "Input";

export { Input };
