import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from './userSlice';

const PostLoginHandler = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const dispatch = useDispatch()

  useEffect(() => {
    const handleLoginEvent = async () => {
      if (authState && authState.isAuthenticated) {     
        const userInfo = await oktaAuth.getUser();    
        dispatch(setUserInfo(userInfo))
     
      }
    };

    handleLoginEvent();
  }, [authState, oktaAuth]);

  return null; // This component doesn't render anything
};

export default PostLoginHandler;
