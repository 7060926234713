import { createSlice } from "@reduxjs/toolkit";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import DescriptionIcon from '@mui/icons-material/Description';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

const initialState = [
  { text: "Dashboard", icon: <MonitorHeartIcon sx={{
    
  }}/>, path: "/" },
  { text: "Catalog", icon: <LibraryBooksIcon />, path: "/catalog" },
  { text: "Deployment", icon: <DashboardIcon />, path: "/deployment" },
  { text: "Permissions", icon: <PeopleIcon />, path: "/permissions" },
  { text: "Documentation", icon: <DescriptionIcon />, path: "/documentation" },
  { text: "Chat", icon: <ChatBubbleOutlineIcon />, path: "/chat" },

];


const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    // You can add reducers if you need to update the menu list dynamically
    setMenuItems: (state, action) => {
      return action.payload;
    },
  },
});

export const { setMenuItems } = menuSlice.actions;
export default menuSlice.reducer;
