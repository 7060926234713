import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer, { drawerClasses } from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import MenuButton from "./MenuButton";
import MenuContent from "./MenuContent";
import "./SideMenuMobile.scss";
import logo from "../../images/platform-icon.png";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

const SideMenuMobile = ({ open, toggleDrawer }) => {
  const userInfo = useSelector((state) => state.user.userInfo);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: "none",
          backgroundColor: "background.paper",
        },
      }}
    >
      <img className="side-menu-mobile-logo" src={logo} alt="logo" />
      <div className="side-menu-logo-title">Developer Portal</div>
      <Stack
        sx={{
          maxWidth: "70dvw",
          height: "100%",
        }}
      >
        <Stack direction="row" sx={{ p: 2, pb: 0, gap: 1 }}>
          {userInfo && (
            <Stack
              direction="row"
              sx={{ gap: 1, alignItems: "center", flexGrow: 1, p: 1 }}
            >
              <Box>
                <span className="side-menu-mobile-avatar">
                  {userInfo?.name?.charAt(0).toUpperCase()}
                </span>
              </Box>
              <Typography component="p" variant="h6">
                {userInfo?.name}
              </Typography>
            </Stack>
          )}
          <MenuButton showBadge>
            <NotificationsRoundedIcon />
          </MenuButton>
        </Stack>
        <Divider />
        <Stack sx={{ flexGrow: 1 }}>
          <MenuContent />
          <Divider />
        </Stack>
        <Stack sx={{ p: 2 }}>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<LogoutRoundedIcon />}
          >
            Logout
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default SideMenuMobile;
