import * as React from 'react';
import  '@mui/x-date-pickers/themeAugmentation';
import  '@mui/x-charts/themeAugmentation';
import  '@mui/x-data-grid/themeAugmentation';
import  '@mui/x-tree-view/themeAugmentation';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppTheme from '../theme/AppTheme';
import AppNavbar from '../components/AppNavbar/AppNavbar';
import SideMenu from '../components/SideMenu/SideMenu';
import Header from '../components/Header/Header';
import { chartsCustomizations } from '../theme/customizations/js/chartsCustomizations';
import { dataGridCustomizations } from '../theme/customizations/js/dataGridCustomizations';
import { datePickersCustomizations } from '../theme/customizations/js/datePickersCustomizations';
import { treeViewCustomizations } from '../theme/customizations/js/treeViewCustomizations';


  
  const xThemeComponents = {
    ...chartsCustomizations,
    ...dataGridCustomizations,
    ...datePickersCustomizations,
    ...treeViewCustomizations,
  };


const Layout = ({props, children }) => {
   
    return (
        <AppTheme {...props} themeComponents={xThemeComponents}>
        <CssBaseline enableColorScheme />
        <Box sx={{ display: 'flex' }}>
          <SideMenu />
          <AppNavbar />
          {/* Main content */}
          <Box
            component="main"
            sx={(theme) => ({
              flexGrow: 1,
              backgroundColor: theme.vars
                ? 'white'
                : alpha(theme.palette.background.default, 1),
              overflow: 'auto',
            })}
          >
            <Stack
              spacing={2}
              sx={{
                /*alignItems: 'center',*/
                mx: 3,
                pb: 3,
                mt: { xs: 8, md: 0 },                
              }}
            >
              <Header />
              {children}
            </Stack>
          </Box>
        </Box>
      </AppTheme>
    );
  };
  
  export default Layout;