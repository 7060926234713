import * as React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Breadcrumbs, { breadcrumbsClasses } from "@mui/material/Breadcrumbs";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: (theme.vars || theme).palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: "center",
  },
}));

const Dashboard = () => {

  const location = useLocation();
  const menuItems = useSelector((state) => state.menu);
  const breadcrumbItems = menuItems.filter((item) =>
    location.pathname.startsWith(item.path)
  );

  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextRoundedIcon fontSize="small" />}
    >
      {breadcrumbItems.map((item, index) => {
        // Check if the current item is the active one
        const isActive = location.pathname === item.path;

        return (
          <Typography
            key={index}
            variant="body1"
            sx={{
              color: isActive ? "text.primary" : "text.secondary", // You can change this color as per your need
              fontWeight: isActive ? 600 : 400, // Make it bold if active, normal if not
            }}
          >
            {item.text}
          </Typography>
        );
      })}
    </StyledBreadcrumbs>
  );
};

export default Dashboard;
