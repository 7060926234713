import React, { useEffect } from "react";
import "./Users.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Box,
} from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";


const Users = ({ handleAdd, setNewItem, setUsers, users,handleSaveUserEdit, handleCancelUserEdit, roles,
  handleUserEdit,selectedTab,newItem,handleSaveNewItem,handleCancelEdit,permissionTypes,editingId }) => {

  



  return (
    <div className="space-y-4">
      <Button
        onClick={() => handleAdd("users")}
        startIcon={<Add />}
        variant="outlined" // Change to outlined for a more subtle effect
        sx={{
          borderColor: "#E6863C", // Set border color to #E6863C
          color: "#E6863C", // Set text color to #E6863C
          "&:hover": {
            backgroundColor: "#E6863C", // Background on hover
            color: "white", // Text color on hover
            borderColor: "#E6863C", // Border color on hover
          },
          marginBottom: 2,
        }}
      >
        Add User
      </Button>

      {newItem && selectedTab === "users" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Username"
            value={newItem.username}
            onChange={(e) =>
              setNewItem({ ...newItem, username: e.target.value })
            }
            fullWidth
            size="small"
            sx={{ maxWidth: 300 }}
          />
          <FormControl fullWidth size="small" sx={{ maxWidth: 300 }}>
            <InputLabel>Permissions</InputLabel>
            <Select
              value={newItem.permissions[0]}
              onChange={(e) =>
                setNewItem({ ...newItem, permissions: [e.target.value] })
              }
              size="small"
            >
              {permissionTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" sx={{ maxWidth: 300 }}>
            <InputLabel>Roles</InputLabel>
            <Select
              value={newItem.roles[0]}
              onChange={(e) =>
                setNewItem({ ...newItem, roles: [e.target.value] })
              }
              size="small"
            >
              {roles.map((role) => (
                <MenuItem key={role.name} value={role.name}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor: "black", // Set the background color to #E6863C
                "&:hover": {
                  backgroundColor: "black", // Set a hover color (a darker shade of #E6863C)
                },
                color: "white", // Set text color to white
              }}
              onClick={handleSaveNewItem}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleCancelEdit}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Permissions</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                key={user.id}
                sx={{ backgroundColor: "#fff", borderBottom: "1px solid #ddd" }}
              >
                <TableCell>
                  {editingId === user.id ? (
                    <TextField
                      value={user.username}
                      onChange={(e) => {
                        const updatedUsers = users.map((u) =>
                          u.id === user.id
                            ? { ...u, username: e.target.value }
                            : u
                        );
                        setUsers(updatedUsers);
                      }}
                      fullWidth
                    />
                  ) : (
                    user.username
                  )}
                </TableCell>
                <TableCell>
                  {editingId === user.id ? (
                    <FormControl fullWidth>
                      <InputLabel>Permissions</InputLabel>
                      <Select
                        multiple
                        value={user.permissions}
                        onChange={(e) => {
                          const updatedUsers = users.map((u) =>
                            u.id === user.id
                              ? { ...u, permissions: e.target.value }
                              : u
                          );
                          setUsers(updatedUsers);
                        }}
                      >
                        <MenuItem value="read">Read</MenuItem>
                        <MenuItem value="write">Write</MenuItem>
                        <MenuItem value="delete">Delete</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    user.permissions.join(", ")
                  )}
                </TableCell>
                <TableCell>
                  {editingId === user.id ? (
                    <FormControl fullWidth>
                      <InputLabel>Roles</InputLabel>
                      <Select
                        value={user.roles[0]} // Assuming each user can only have one role for simplicity
                        onChange={(e) => {
                          const updatedUsers = users.map((u) =>
                            u.id === user.id
                              ? { ...u, roles: [e.target.value] }
                              : u
                          );
                          setUsers(updatedUsers);
                        }}
                      >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="editor">Editor</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    user.roles.join(", ")
                  )}
                </TableCell>
                <TableCell align="right">
                  {editingId === user.id ? (
                   <Box sx={{ display: "flex", gap: 1 ,float:'right'}}>
                      <Button
                        onClick={() => handleSaveUserEdit(user)} // Save the changes
                        variant="contained"
                        sx={{
                          fontSize: "0.75rem",
                          backgroundColor: "black", // Set the background color to #E6863C
                          "&:hover": {
                            backgroundColor: "black", // Set a hover color (a darker shade of #E6863C)
                          },
                          color: "white", // Set text color to white
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={handleCancelUserEdit} // Cancel editing
                        variant="outlined"
                        color="error"
                        size="small"
                      >
                        Cancel
                      </Button>
                      </Box>
                  ) : (
                    <Box sx={{ display: "flex", gap: 1,float:'right' }}>
                      <IconButton
                        size="small"
                      
                        onClick={() => handleUserEdit(user)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() =>
                          setUsers(users.filter((u) => u.id !== user.id))
                        }
                      >
                        <Delete />
                      </IconButton>
                      </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Users;
