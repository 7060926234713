/*import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuContent from "./MenuContent";
import OptionsMenu from "./OptionsMenu";
import logo from "../../images/platform-icon.png";
import "./SideMenu.scss";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: "border-box",
  },
});

const SideMenu = () => {
  const userInfo = useSelector((state) => state.user.userInfo);



  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", md: "block" },
        boxShadow: '5px 0 5px -5px #fff',
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: "#FCFCFC",
          boxShadow:'-5px 10px 15px rgba(0, 0, 0, 0.2);'
        },
      }}
    >
      <img className="side-menu-logo" src={logo} alt="logo" />
      <div className="side-menu-logo-title">Developer Portal</div>
      <Box
        sx={{
          display: "flex",
        }}
      ></Box>
      <Divider />
      <MenuContent />
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: "center",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        {userInfo && (
          <React.Fragment>
            <Box>
              <span className="side-menu-avatar">
                {userInfo?.name?.charAt(0).toUpperCase()}
              </span>
            </Box>
            <Box sx={{ mr: "auto" }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, lineHeight: "16px" }}
              >
                {userInfo?.name}
              </Typography>
            </Box>
            <OptionsMenu />
          </React.Fragment>
        )}
        
      </Stack>
    </Drawer>
  );
};

export default SideMenu;
*/
import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuContent from "./MenuContent";
import OptionsMenu from "./OptionsMenu";
import logo from "../../images/platform-icon.png";
import "./SideMenu.scss";
import { useSelector } from "react-redux";

const drawerWidth = 240;
const collapsedWidth = 65;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: collapsedWidth,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const SideMenu = () => {
  const [open, setOpen] = React.useState(true);
  const userInfo = useSelector((state) => state.user.userInfo);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        display: { xs: "none", md: "block" },
        boxShadow: '5px 0 5px -5px #fff',
        '& .MuiDrawer-paper': {
          backgroundColor: "#FCFCFC",
          boxShadow: '-5px 10px 15px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      {/* Logo and Title Section */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'flex-end',
        pr: 2,
        pt: 1
      }}>
        <IconButton onClick={handleDrawerToggle} size="small"
         sx={{ width: 32, height: 32 }} 
        >
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        p: 1,
        pb: 0
      }}>
        <img 
          className={open ? "side-menu-logo" : "side-menu-logo-small"} 
          src={logo} 
          alt="logo" 
        />
        {open && (
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: 'center',
              mb: 1,
              fontWeight: 500,
              fontSize:12
            }}
          >
            Developer Portal
          </Typography>
        )}
      </Box>

      {/* Toggle Button Section */}
     

      <Divider />
      
      <MenuContent collapsed={!open} />
      
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: "center",
          borderTop: "1px solid",
          borderColor: "divider",
          justifyContent: !open ? 'center' : 'flex-start'
        }}
      >
        {userInfo && (
          <React.Fragment>
            <Box>
              <span className="side-menu-avatar">
                {userInfo?.name?.charAt(0).toUpperCase()}
              </span>
            </Box>
            {open && (
              <>
                <Box sx={{ mr: "auto" }}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 500, lineHeight: "16px" }}
                  >
                    {userInfo?.name}
                  </Typography>
                </Box>
                <OptionsMenu />
              </>
            )}
          </React.Fragment>
        )}
      </Stack>
    </Drawer>
  );
};

export default SideMenu;