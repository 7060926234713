
import deploymentReducer from './Deployment/deploymentSlice';
import userReducer from '../okta/userSlice';
import catalogReducer from './Catalog/catalogSlice';
import menuReducer from './SideMenu/menuSlice';
import chatReducer from './Chat/chatSlice';
import { configureStore } from '@reduxjs/toolkit';


const store = configureStore({
  reducer: {   
    deployments: deploymentReducer,
    user:userReducer,
    catalog:catalogReducer,
    menu:menuReducer,
    chat:chatReducer
  },
});

export default store;
