import React from 'react';
import { Search, ChevronDown, ArrowUpDown } from 'lucide-react';

const FilterServices = () => {
  return (
    <div className="w-full px-4 py-2 bg-white  flex items-center gap-4">
      {/* Search Box */}
      <div className="flex-1 max-w-md relative">
        <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none" >
          <Search className="h-4 w-4 text-gray-400" />
        </div>
        <input
          type="text"
          placeholder="Search services..."
          className="w-full pl-10 pr-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      {/* Filter Dropdowns */}
      <div className="flex items-center gap-2">
        <div className="relative">
          <button className="flex items-center gap-2 px-3 py-2 text-sm border rounded-lg hover:bg-gray-50">
            Team
            <ChevronDown className="h-4 w-4 text-gray-500" />
          </button>
        </div>

        <div className="relative">
          <button className="flex items-center gap-2 px-3 py-2 text-sm border rounded-lg hover:bg-gray-50">
            Status
            <ChevronDown className="h-4 w-4 text-gray-500" />
          </button>
        </div>

        <div className="relative">
          <button className="flex items-center gap-2 px-3 py-2 text-sm border rounded-lg hover:bg-gray-50">
            App Group
            <ChevronDown className="h-4 w-4 text-gray-500" />
          </button>
        </div>
      </div>

      {/* Sort Button */}
      <div className="relative">
        <button className="flex items-center gap-2 px-3 py-2 text-sm border rounded-lg hover:bg-gray-50">
          Sort
          <ArrowUpDown className="h-4 w-4 text-gray-500" />
        </button>
      </div>
    </div>
  );
}
export default FilterServices
