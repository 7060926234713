import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import "./ManagePermissions.scss";
import Users from "./Users";
import Permissions from "./Permissions";
import Roles from "./Roles";

const ManagePermissions = () => {
  const [users, setUsers] = useState([
    { id: 1, username: "john_doe", permissions: ["read"], roles: ["admin"] },
  ]);

  const [roles, setRoles] = useState([
    {
      id: 1,
      name: "admin",
      description: "Administrator",
      permissions: ["create", "read", "update", "delete"],
    },
  ]);

  const [permissions, setPermissions] = useState([
    { id: 1, name: "user_management", type: "create" },
  ]);

  const [selectedTab, setSelectedTab] = useState("users");
  const [editingId, setEditingId] = useState(null);
  const [newItem, setNewItem] = useState(null);
  const [editingPermissionId, setEditingPermissionId] = useState(null);
  const [editedPermission, setEditedPermission] = useState(null);

  const [editingRoleId, setEditingRoleId] = useState(null);
    const [editedRole, setEditedRole] = useState(null);

  const permissionTypes = ["create", "read", "update", "delete"];

  const handleEdit = (item) => {
    setEditingId(item.id);
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setNewItem(null);
  };

  const handleSaveNewItem = () => {
    if (selectedTab === "users") {
      setUsers([...users, newItem]);
    } else if (selectedTab === "roles") {
      setRoles([...roles, newItem]);
    } else if (selectedTab === "permissions") {
      setPermissions([...permissions, newItem]);
    }
    setNewItem(null); // Reset new item state after save
  };

  const handleAdd = (section) => {
    const newId =
      Math.max(
        ...(section === "users"
          ? users
          : section === "roles"
          ? roles
          : permissions
        ).map((item) => item.id)
      ) + 1;
    const defaultNewItem = {
      id: newId,
      username: "",
      permissions: ["read"],
      roles: ["admin"],
      name: "",
      description: "",
      type: "read",
    };
    setNewItem(defaultNewItem);
  };

  const handleUserEdit = (user) => {
    setEditingId(user.id); // Set the user ID to indicate this row is in edit mode
  };

  const handleCancelUserEdit = () => {
    setEditingId(null); // Reset editingId to cancel editing
  };

  const handleSaveUserEdit = (user) => {
    const updatedUsers = users.map((u) =>
      u.id === user.id
        ? {
            ...u,
            username: user.username,
            permissions: user.permissions,
            roles: user.roles,
          }
        : u
    );
    setUsers(updatedUsers); // Save the edited data
    setEditingId(null); // Reset editingId after saving
  };

  const TabPanel = ({ value, index, children }) => {
    return value === index ? <div>{children}</div> : null;
  };



  return (
    <div className="p-4 space-y-4">
      <h1 className="text-2xl font-bold">Permission Management</h1>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
      >
        <Tab label="Users" value="users" />
        <Tab label="Roles" value="roles" />
        <Tab label="Permissions" value="permissions" />
      </Tabs>

      <TabPanel value={selectedTab} index="users">
        <Users
          handleAdd={handleAdd}
          setNewItem={setNewItem}
          setUsers={setUsers}
          users={users}
          handleSaveUserEdit={handleSaveUserEdit}
          handleCancelUserEdit={handleCancelUserEdit}
          handleUserEdit={handleUserEdit}
          handleSaveNewItem={handleSaveNewItem}
          handleCancelEdit={handleCancelEdit}
          newItem={newItem}
          selectedTab={selectedTab}
          permissionTypes={permissionTypes}
          editingId={editingId}
          roles={roles}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index="roles">
   
        <Roles
            handleAdd={handleAdd}
            setNewItem={setNewItem}
            setUsers={setUsers}
            users={users}
            handleSaveUserEdit={handleSaveUserEdit}
            handleCancelUserEdit={handleCancelUserEdit}
            handleUserEdit={handleUserEdit}
            handleSaveNewItem={handleSaveNewItem}
            handleCancelEdit={handleCancelEdit}
            newItem={newItem}
            selectedTab={selectedTab}
            permissionTypes={permissionTypes}
            editingId={editingId}
            roles={roles}
            setRoles={setRoles}
            permissions={permissions}
            setPermissions={setPermissions}
            editingRoleId={editingRoleId}
            setEditingRoleId={setEditingRoleId}
            editedRole={editedRole}
            setEditedRole={setEditedRole}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index="permissions">
        <Permissions
          handleAdd={handleAdd}
          setNewItem={setNewItem}
          setUsers={setUsers}
          users={users}
          handleSaveUserEdit={handleSaveUserEdit}
          handleCancelUserEdit={handleCancelUserEdit}
          handleUserEdit={handleUserEdit}
          handleSaveNewItem={handleSaveNewItem}
          handleCancelEdit={handleCancelEdit}
          newItem={newItem}
          selectedTab={selectedTab}
          permissionTypes={permissionTypes}
          editingId={editingId}
          roles={roles}
          permissions={permissions}
          setPermissions={setPermissions}
          editingPermissionId={editingPermissionId}
          setEditingPermissionId={setEditingPermissionId}
          editedPermission={editedPermission}
          setEditedPermission={setEditedPermission}
        />
      </TabPanel>
    </div>
  );
};

export default ManagePermissions;
