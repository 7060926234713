// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Deployment from './components/Deployment/Deployment';
import OktaProvider from './okta/OktaProvider';
import Login from './okta/Login';
import { LoginCallback } from '@okta/okta-react';
import Logout from './okta/Logout';
import PrivateRoute from './okta/PrivateRoute';
import Profile from './components/Profile/Profile';
import PostLoginHandler from './okta/PostLoginHandler';
import Catalog from './components/Catalog/Catalog';
import ManagePermissions from './components/ManagePermissions/ManagePermissions';
import Dashboard from './components/Dashboard/Dashboard';
import Workflow from './components/Deployment/Workflow';
import Config from './components/Deployment/Config';
import ServicePage from './components/Catalog/ServicePage';
import ServiceDocs from './components/Catalog/ServiceDocs';
import Chat from './components/Chat/Chat';
import Documentation from './components/Documentation/Documentation';






const App = () => {
  return (

    <Router>
      <OktaProvider>
      <PostLoginHandler />
        <Routes>
          <Route path="/login/callback" element={<LoginCallback />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute><Layout><Dashboard /></Layout></PrivateRoute>} />
          <Route path="/catalog" element={<PrivateRoute><Layout><Catalog /></Layout></PrivateRoute>} />
          <Route path="/workflow" element={<PrivateRoute><Layout><Workflow /></Layout></PrivateRoute>} />
          <Route path="/deployment" element={<PrivateRoute><Layout><Deployment /></Layout></PrivateRoute>} />
          <Route path="/deployment-config" element={<PrivateRoute><Layout><Config /></Layout></PrivateRoute>} />
          <Route path="/permissions" element={<PrivateRoute><Layout><ManagePermissions /></Layout></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Layout><Profile /></Layout></PrivateRoute>} />
          <Route path="/servicePage" element={<PrivateRoute><Layout><ServicePage /></Layout></PrivateRoute>} />
          <Route path="/serviceDocs" element={<PrivateRoute><Layout><ServiceDocs /></Layout></PrivateRoute>} />
          <Route path="/documentation" element={<PrivateRoute><Layout><Documentation /></Layout></PrivateRoute>} />
          <Route path="/chat" element={<PrivateRoute><Layout><Chat /></Layout></PrivateRoute>} />
          <Route path="/logout" element={<PrivateRoute><Logout /></PrivateRoute>} />
        </Routes>
      </OktaProvider>
    </Router>

  );
}

export default App;
