import React, { useState } from "react";
import {
  AccessAlarm,
  CheckCircle,
  Cancel,
  Warning,
  PlayArrow,
  RotateLeft,
  Terminal,
  Code,
  Person,
  CalendarToday,
  FilterList,
  OpenInNew,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  Button,
  Badge,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import SettingsIcon from "@mui/icons-material/Settings";
import { useLocation, useNavigate } from "react-router-dom";

const DeploymentsDashboard = ({ service, back = false }) => {
  const location = useLocation();
  const [selectedEnvironment, setSelectedEnvironment] = useState("production");
  const [selectedTimeframe, setSelectedTimeframe] = useState("24h");
  const [value, setValue] = useState(0);
  const navigate = useNavigate(); 
  React.useEffect(() => {
    console.log("back", location.state?.back);
    console.log("service", location.state?.service);
  }, []);

  const mockDeployments = [
    {
      id: "dep-123",
      status: "success",
      environment: "production",
      version: "v2.1.0",
      commit: "3a7b22c",
      timestamp: "14:30",
      duration: "5m 23s",
      triggeredBy: "Sarah Chen",
      description: "Release customer feedback feature",
    },
    {
      id: "dep-122",
      status: "failed",
      environment: "production",
      version: "v2.0.9",
      commit: "8f1d4e5",
      timestamp: "10:15",
      duration: "2m 45s",
      triggeredBy: "Mike Johnson",
      description: "Update payment gateway integration",
    },
    {
      id: "dep-121",
      status: "in_progress",
      environment: "staging",
      version: "v2.1.1",
      commit: "2c9f7b6",
      timestamp: "09:45",
      duration: "3m 12s",
      triggeredBy: "Alex Turner",
      description: "Implement new search algorithm",
    },
  ];

  const getStatusColor = (status) => {
    const colors = {
      success: "green",
      failed: "red",
      in_progress: "orange",
    };
    return colors[status] || "grey";
  };

  const getStatusBadge = (status) => {
    const styles = {
      success: "success",
      failed: "error",
      in_progress: "warning",
    };
    return styles[status] || "default";
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleReturnToCatalog = () => {
    navigate("/catalog");
  };
  return (
    <div className="min-h-screen bg-gray-50/50">
      {
        location.state?.back &&
        (
          <div>
          <Button
            variant="outlined"
            color="primary"
            size="small" // Makes the button small
            startIcon={<ArrowBackIcon />}
            onClick={handleReturnToCatalog}
            sx={{
              fontSize: "0.75rem", // Adjust font size to be smaller
              padding: "4px 12px", // Make padding smaller for a compact button
              marginBottom: "10px",
            }}
          >
            Back to Catalog
          </Button>
        </div>
        )
      }
      <div className="max-w-7xl mx-auto p-8">
        {/* Header */}
        <div className="flex justify-between items-start mb-8">
          <div>
            <h1 className="text-2xl font-semibold text-gray-900">
              Deployments
            </h1>
            <p className="text-sm text-gray-500 mt-1">
              Monitor and manage your application deployments
            </p>
          </div>
          <div className="flex gap-4">
            {" "}
            {/* This div wraps the buttons to keep them next to each other */}
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlayArrow />}
              onClick={() => alert("Deploy")}
            >
              Deploy
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SettingsIcon />}
              onClick={() =>   navigate('/deployment-config')}
            >
              Settings
            </Button>
          </div>
        </div>

        {/* Stats Overview */}
        <div className="grid grid-cols-4 gap-4 mb-8">
          {[
            {
              label: "Total Deployments",
              value: "1,234",
              trend: "+12% from last month",
            },
            {
              label: "Success Rate",
              value: "98.5%",
              trend: "+2.1% from last week",
            },
            {
              label: "Avg. Duration",
              value: "4m 12s",
              trend: "-30s from average",
            },
            {
              label: "Daily Deployments",
              value: "12",
              trend: "On track with target",
            },
          ].map((stat, index) => (
            <Card key={index} sx={{ border: 0, boxShadow: 1 }}>
              <CardContent>
                <p className="text-sm font-medium text-gray-500">
                  {stat.label}
                </p>
                <p className="text-2xl font-semibold mt-2 text-gray-900">
                  {stat.value}
                </p>
                <p className="text-xs text-gray-500 mt-1">{stat.trend}</p>
              </CardContent>
            </Card>
          ))}
        </div>

        {/* Active Deployment */}
        <Card
          sx={{
            mb: 8,
            backgroundColor: "orange.50",
            border: 1,
            borderColor: "orange.100",
          }}
        >
          <CardContent>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="h-2 w-2 bg-orange-500 rounded-full animate-pulse" />
                <span className="text-sm font-medium text-orange-700">
                  Deployment in Progress
                </span>
              </div>
              <Button
                variant="outlined"
                color="orange"
                size="small"
                endIcon={<OpenInNew />}
              >
                View Details
              </Button>
            </div>
            <div className="mt-3 text-orange-900 font-medium">
              Implementing new search algorithm (v2.1.1)
            </div>
          </CardContent>
        </Card>

        {/* Filters */}
        <div className="flex items-center justify-between mb-6">
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="deployment tabs"
            className="w-auto"
          >
            <Tab label="All" />
            <Tab label="Production" />
            <Tab label="Staging" />
            <Tab label="Development" />
          </Tabs>

          <Box display="flex" alignItems="center" gap={3}>
            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
              <InputLabel>Timeframe</InputLabel>
              <Select
                value={selectedTimeframe}
                onChange={(e) => setSelectedTimeframe(e.target.value)}
                label="Timeframe"
              >
                <MenuItem value="24h">Last 24h</MenuItem>
                <MenuItem value="7d">Last 7 days</MenuItem>
                <MenuItem value="30d">Last 30 days</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<FilterList />}
            >
              Filter
            </Button>
          </Box>
        </div>

        {/* Deployment History */}
        <div className="space-y-3">
          {mockDeployments.map((deployment) => (
            <Card
              key={deployment.id}
              sx={{ border: 0, boxShadow: 1, "&:hover": { boxShadow: 3 } }}
            >
              <CardContent>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div
                      className={`flex items-center justify-center w-10 h-10 rounded-full bg-${getStatusColor(
                        deployment.status
                      )}`}
                    >
                      {deployment.status === "success" && (
                        <CheckCircle sx={{ fontSize: 24 }} />
                      )}
                      {deployment.status === "failed" && (
                        <Cancel sx={{ fontSize: 24 }} />
                      )}
                      {deployment.status === "in_progress" && (
                        <Warning sx={{ fontSize: 24 }} />
                      )}
                    </div>
                    <div>
                      <div className="flex items-center gap-2 mb-1">
                        <h3 className="text-sm font-medium text-gray-900">
                          {deployment.description}
                        </h3>
                        <Badge color={getStatusBadge(deployment.status)}>
                          {deployment.status.charAt(0).toUpperCase() +
                            deployment.status.slice(1)}
                        </Badge>
                      </div>
                      <div className="flex items-center gap-4 text-xs text-gray-500">
                        <div className="flex items-center gap-1">
                          <Code sx={{ fontSize: 16 }} />
                          {deployment.version}
                        </div>
                        <div className="flex items-center gap-1">
                          <Person sx={{ fontSize: 16 }} />
                          {deployment.triggeredBy}
                        </div>
                        <div className="flex items-center gap-1">
                          <AccessAlarm sx={{ fontSize: 16 }} />
                          {deployment.timestamp}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <Button
                      variant="text"
                      size="small"
                      color="default"
                      startIcon={<Terminal sx={{ fontSize: 16 }} />}
                    >
                      Logs
                    </Button>
                    <Button
                      variant="text"
                      size="small"
                      color="default"
                      startIcon={<RotateLeft sx={{ fontSize: 16 }} />}
                    >
                      Retry
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeploymentsDashboard;

/*import React, { useEffect } from "react";
import './Deployment.scss';
import { Box,  Grid2 } from '@mui/material';
import Search from "../Header/Search";
import DeploymentGrid from "./DeploymentGrid";


const Deployment = () => {

  
    return (
        <React.Fragment>
            <Box sx={{ padding: 2 }}>

                <Grid2 container spacing={2}>
                  
                    <Grid2 item xs={12}>
                        <Search />
                    </Grid2>
                </Grid2>

                <Grid2 item xs={12} marginTop={2} >
                    <DeploymentGrid />
                </Grid2>
            </Box>
        </React.Fragment>
    );
};

export default Deployment;
*/
