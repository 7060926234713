import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from "recharts";
import { Activity, Clock, AlertTriangle, Percent } from "lucide-react";
import { Grid2, Paper } from "@mui/material";

const MetricCard = ({ title, value, subtitle, icon: Icon }) => (
  <div className="w-full h-full bg-white rounded-lg  p-4 flex flex-col justify-between">
    <div className="flex justify-between items-start">
      <div>
        <h3 className="text-gray-500 text-sm">{title}</h3>
        <p className="text-2xl font-bold mt-2">{value}</p>
      </div>
      <Icon className="w-8 h-8 text-[#E6863C]" />
    </div>
    <p className="text-gray-500 text-sm mt-2">{subtitle}</p>
  </div>
);

const Dashboard = () => {
  const [chartWidth, setChartWidth] = useState(450);

  useEffect(() => {
    const updateWidth = () => {
      const width = window.innerWidth;
      const containerWidth = width > 1280 ? 1280 : width - 48; // max-width or screen width minus padding
      const chartWidth = containerWidth / 2 - 48; // divide by 2 for two charts per row, minus padding
      setChartWidth(chartWidth);
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  // Mock data (same as before)
  const deploymentData = [
    { name: "Week 1", value: 12 },
    { name: "Week 2", value: 15 },
    { name: "Week 3", value: 18 },
    { name: "Week 4", value: 14 },
  ];

  const leadTimeData = [
    { name: "Week 1", value: 22 },
    { name: "Week 2", value: 19 },
    { name: "Week 3", value: 20 },
    { name: "Week 4", value: 18 },
  ];

  const mttrData = [
    { name: "Week 1", value: 2.5 },
    { name: "Week 2", value: 2.2 },
    { name: "Week 3", value: 1.9 },
    { name: "Week 4", value: 2.1 },
  ];

  const failureRateData = [
    { name: "Week 1", value: 4.5 },
    { name: "Week 2", value: 3.8 },
    { name: "Week 3", value: 4.2 },
    { name: "Week 4", value: 4.0 },
  ];

  const runtimeData = [
    { name: "Node.js", value: 45 },
    { name: "Python", value: 30 },
    { name: "Java", value: 15 },
    { name: "Go", value: 10 },
  ];

  const frameworkData = [
    { name: "React", value: 40 },
    { name: "Vue", value: 25 },
    { name: "Angular", value: 20 },
    { name: "Svelte", value: 15 },
  ];

  const COLORS = ["red", "#F4A261", "#2A9D8F", "#264653"];

  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Developer Dashboard</h1>
      <Grid2 container spacing={2} margin={5} gap={5} justifyContent={"center"}>
        <Grid2 item xs={12} sm={6} md={3}>
          <Paper
            sx={{
              padding: "20px",
              backgroundColor: "white",
              boxShadow: 1, // MUI predefined box shadow level
              borderRadius: "8px", // Optional: to give it rounded corners
            }}
          >
            <MetricCard
              title="Deployment Frequency"
              value="14.7/week"
              subtitle="Last 30 days average"
              icon={Activity}
            />
          </Paper>
        </Grid2>
        <Grid2 item xs={12} sm={6} md={3}>
          <Paper
            sx={{
              padding: "20px",
              backgroundColor: "white",
              boxShadow: 1, // MUI predefined box shadow level
              borderRadius: "8px", // Optional: to give it rounded corners
            }}
          >
            <MetricCard
              title="Lead Time"
              value="20 hours"
              subtitle="Average time to deploy"
              icon={Clock}
            />
          </Paper>
        </Grid2>
        <Grid2 item xs={12} sm={6} md={3}>
          <Paper
            sx={{
              padding: "20px",
              backgroundColor: "white",
              boxShadow: 1, // MUI predefined box shadow level
              borderRadius: "8px", // Optional: to give it rounded corners
            }}
          >
            <MetricCard
              title="MTTR"
              value="2.2 hours"
              subtitle="Mean time to recovery"
              icon={AlertTriangle}
            />
          </Paper>
        </Grid2>
        <Grid2 item xs={12} sm={6} md={3}>
          <Paper
            sx={{
              padding: "20px",
              backgroundColor: "white",
              boxShadow: 1, // MUI predefined box shadow level
              borderRadius: "8px", // Optional: to give it rounded corners
            }}
          >
            <MetricCard
              title="Change Failure Rate"
              value="4.1%"
              subtitle="Last 30 days average"
              icon={Percent}
            />
          </Paper>
        </Grid2>
      </Grid2>

      {/* Charts Grid */}
      <div className="flex flex-wrap gap-4">
        {/* Row 1 */}
        <div className="w-full md:w-[calc(50%-8px)] bg-white p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-4">
            Deployment Frequency Trend
          </h3>
          <BarChart width={chartWidth} height={250} data={deploymentData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#2A9D8F" />
          </BarChart>
        </div>

        <div className="w-full md:w-[calc(50%-8px)] bg-white p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-4">Lead Time Trend</h3>
          <LineChart width={chartWidth} height={250} data={leadTimeData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="#E6863C" />
          </LineChart>
        </div>

        {/* Row 2 */}
        <div className="w-full md:w-[calc(50%-8px)] bg-white p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-4">MTTR Trend</h3>
          <LineChart width={chartWidth} height={250} data={mttrData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="#E6863C" />
          </LineChart>
        </div>

        <div className="w-full md:w-[calc(50%-8px)] bg-white p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-4">
            Change Failure Rate Trend
          </h3>
          <LineChart width={chartWidth} height={250} data={failureRateData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="#264653" />
          </LineChart>
        </div>

        {/* Row 3 */}
        <div className="w-full md:w-[calc(50%-8px)] bg-white p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-4">Runtime Distribution</h3>
          <PieChart width={chartWidth} height={250}>
            <Pie
              data={runtimeData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              paddingAngle={5}
              dataKey="value"
            >
              {runtimeData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>

        <div className="w-full md:w-[calc(50%-8px)] bg-white p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-4">Framework Distribution</h3>
          <PieChart width={chartWidth} height={250}>
            <Pie
              data={frameworkData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              paddingAngle={5}
              dataKey="value"
            >
              {frameworkData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
