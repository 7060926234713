import React, { useState } from "react";
import "./Permissions.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Box,
} from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";

const Permissions = ({
  handleAdd,
  setNewItem, 
  selectedTab,
  newItem,
  handleSaveNewItem,
  handleCancelEdit,
  permissionTypes,
  editingPermissionId, 
  setEditingPermissionId,
  editedPermission, 
  setEditedPermission,
  permissions,
  setPermissions
}) => {


  // Function to handle the edit action
  const handleEditPermission = (permission) => {
    setEditingPermissionId(permission.id);
    console.log("permission.id", permission.id);
    setEditedPermission({ ...permission }); // Copy the permission to avoid direct mutation
  };

  // Function to handle the save action for an edited permission
  const handleSavePermissionEdit = () => {
    setPermissions(
      permissions.map((p) =>
        p.id === editedPermission.id ? editedPermission : p
      )
    );
    setEditingPermissionId(null);
    setEditedPermission(null);
  };

  // Function to handle canceling the edit
  const handleCancelPermissionEdit = () => {
    setEditingPermissionId(null);
    setEditedPermission(null);
  };

  return (
    <div className="space-y-4">
      <Button
        onClick={() => handleAdd("permissions")}
        startIcon={<Add />}
        variant="outlined" // Change to outlined for a more subtle effect
        sx={{
          borderColor: "#E6863C", // Set border color to #E6863C
          color: "#E6863C", // Set text color to #E6863C
          "&:hover": {
            backgroundColor: "#E6863C", // Background on hover
            color: "white", // Text color on hover
            borderColor: "#E6863C", // Border color on hover
          },
          marginBottom: 2,
        }}
      >
        Add Permission
      </Button>

      {newItem && selectedTab === "permissions" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Permission Name"
            value={newItem.name}
            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
            fullWidth
            size="small"
            sx={{ maxWidth: 300 }}
          />
          <FormControl fullWidth size="small" sx={{ maxWidth: 300 }}>
            <InputLabel id="permission-type-label">Permission Type</InputLabel>
            <Select
              labelId="permission-type-label"
              value={newItem.type}
              onChange={(e) => setNewItem({ ...newItem, type: e.target.value })}
              size="small"
              label="Permission Type"
              sx={{
                backgroundColor: "transparent",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "transparent",
                },
                "& .MuiSelect-icon": {
                  color: "#000",
                },
              }}
            >
              {permissionTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
            <Button
             size="small"
             variant="contained"
             sx={{
               backgroundColor: "black", // Set the background color to #E6863C
               "&:hover": {
                 backgroundColor: "black", // Set a hover color (a darker shade of #E6863C)
               },
               color: "white", // Set text color to white
             }}
              onClick={handleSaveNewItem}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleCancelEdit}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions.map((permission) => (
              <TableRow key={permission.id}
              sx={{ backgroundColor: "#fff", borderBottom: "1px solid #ddd" }}>
                <TableCell>
                  {editingPermissionId === permission.id ? (
                    <TextField
                      value={editedPermission.name}
                      onChange={(e) =>
                        setEditedPermission({
                          ...editedPermission,
                          name: e.target.value,
                        })
                      }
                      size="small"
                      fullWidth
                    />
                  ) : (
                    permission.name
                  )}
                </TableCell>
                <TableCell>
                  {editingPermissionId === permission.id ? (
                    <Select
                      value={editedPermission.type}
                      onChange={(e) =>
                        setEditedPermission({
                          ...editedPermission,
                          type: e.target.value,
                        })
                      }
                      size="small"
                      fullWidth
                    >
                      {permissionTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    permission.type
                  )}
                </TableCell>
                <TableCell>
                  {editingPermissionId === permission.id ? (
                    <Box sx={{ display: "flex", gap: 1, float:'right' }}>
                      <Button
                       sx={{
                        
                        fontSize: "0.75rem",
                        backgroundColor: "black", // Set the background color to #E6863C
                        "&:hover": {
                          backgroundColor: "black", // Set a hover color (a darker shade of #E6863C)
                        },
                        color: "white", // Set text color to white
                      }}
                        onClick={handleSavePermissionEdit}
                      >
                        Save
                      </Button>
                      <Button
                     variant="outlined"
                     color="error"
                     size="small"
                        onClick={handleCancelPermissionEdit}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", gap: 1, float:'right' }}>
                      <IconButton
                       size="small"
                        onClick={() => handleEditPermission(permission)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() =>
                          setPermissions(
                            permissions.filter((p) => p.id !== permission.id)
                          )
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Permissions;
