import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import logo from '../images/platform-icon.png'
import okta from '../images/okta.png'
import './Login.scss';

const Login = () => {
    const { oktaAuth } = useOktaAuth();

    const handleLogin = () => {
        oktaAuth.signInWithRedirect();
    };

    return (
        <div className='login'>
            <div className="login-container">
                <div class="logo">
                    <img src={logo} alt="Logo 1" />
                </div>
                <div class="logo-okta">
                    <img src={okta} alt="Logo 2" />
                </div>
                <div class="description">
                    <div>You've been logged out of Sonic.  </div>
                    <div>Please click on the 'Connect with OKTA' button to log in to the system.</div>
                </div>
                <div class="button-container">
                    <button onClick={handleLogin}>Connect with OKTA</button>
                </div>
            </div>
        </div>

    );
};

export default Login;
